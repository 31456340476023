import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import Navigation from "../components/Navigation";
import { signOut } from "../store/action/authActions";
import { database } from "../firebase";
import Employee from "../components/Employee";

const Sidebar = ({ profile, signOut, auth, arrEmployees }) => {
  const [name, setName] = useState("");
  const [picture, setPicture] = useState("");

  useEffect(() => {
    database
      .collection("employees")
      .get()
      .then(data => {
        data.forEach(employee => {
          if (employee.data().uid === auth.uid) {
            setName(employee.data().firstName);
            setPicture(employee.data().picture);
          }
        });
      });
  }, [auth]);

  useEffect(() => {
    if (arrEmployees !== undefined) {
      let employeesArrValue = Object.values(arrEmployees)
      employeesArrValue.forEach(employee => {
        if(employee && employee.uid !== ''){
          if(employee.uid === auth.uid){
            setPicture(employee.picture);
          }
        }
      })
    }
  }, [arrEmployees, auth.uid]);

  return (
    <aside className="sidebar">
      <header className="sidebar-header">
        <span className="sidebar-header__name">
          <Avatar src={picture} round={true} size={45} />
          <span className="sidebar-header__name-text">{name}</span>
        </span>
      </header>
      <Navigation
        signOut={() => {
          signOut();
          setPicture("");
          setName("");
        }}
      />
    </aside>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    arrEmployees: state.firestore.data.employees
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
