import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { database } from "../firebase";
import { firestoreConnect } from "react-redux-firebase";
import {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
  DemoData
} from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";
import moment from "moment";
import withDragDropContext from "./withDnDContext";
import ProjectCalendar from "../components/Calendar/ProjectCalendar";
import EditEvent from "../components/Calendar/EditEvent";
import CreateEvent from "../components/Calendar/CreateEvent";
import ViewOneProject from "../components/Calendar/ViewOneproject";
import AllProjectsList from "../components/Popup/AllProjectsList";
import TransitionsModal from "./../components/Popup/Validator";
import Loader from './../components/Loader/Loader';
import {
  addEmployeeCalendar,
  moveEmployee,
  moveStartDate,
  moveEndDate,
  editEmployeeCalendar,
  deleteEmployeeInProject
} from "../store/action/projectActions";

let schedulerData = new SchedulerData(
  moment.updateLocale("en", { week: { dow: 1 } }),
  ViewTypes.Week,
  false,
  false,
  {
    eventItemPopoverEnabled: false,
    schedulerWidth: "85%",
    nonWorkingTimeBodyBgColor: "#FFFFFF",
    nonWorkingTimeHeadBgColor: "#FFFFFF",
    nonWorkingTimeHeadColor: "#000000",
    defaultEventBgColor: "#538DFF",
    movable: true,
    taskName: "Projects",
    resourceName: "Projects",

    nonAgendaOtherCellHeaderFormat: "dddd, DD",
    views: [
      {
        viewName: "All projects",
        viewType: ViewTypes.Week,
        showAgenda: false,
        isEventPerspective: false
      }
    ]
  }
);

class Home extends Component {
  constructor(props) {
    super(props);
    schedulerData.localeMoment.locale("en");

    this.state = {
      viewModel: schedulerData,
      startDate: "",
      endDate: "",
      show: false,
      showEdit: false,
      filterValue: "",
      selectedEmployees: [],
      listArr: [],
      resourceId: "",
      slotName: "",
      view: "allProjects",
      selectedProject: { id: "select_project", name: "Select project" },
      isValid: true,
      vacationName: "",
      vacationSurname: "",
      newEmployees: [],
      loading: false,
    };
  }
  componentDidMount(){     
    this.getEmployee()
  }

  render() {
    const { projects, employees } = this.props;
    const {
      viewModel,
      startDate,
      endDate,
      show,
      selectedEmployees,
      filterValue,
      listArr,
      view,
      selectedProject,
      showEdit,
    } = this.state;

    let headerView =
      view === "oneProject" ? (
        <AllProjectsList
          projects={projects}
          getProjectItem={this.getProjectItem}
          selectedProject={selectedProject}
        />
      ) : (
        ""
      );

    let firstSelectProject =
      selectedProject.length === 0 && projects !== undefined
        ? projects && projects[0]
        : selectedProject;

    const validator = !this.state.isValid ? (
      <TransitionsModal
        name={this.state.vacationName}
        surname={this.state.vacationSurname}
        open={this.state.open}
        isClose={this.isClose}
      />
    ) : null;

    const loader = this.state.loading ? <Loader/> : null;

    return (
      <React.Fragment>
        {loader}
        <header className="cabinet-header">
          <div className="cabinet-header-block">
            <div></div>
            <div>{headerView}</div>
            <div className="header-panel header-panel-home">
              <p>View</p>
              <div className="switch">
                <div className="toggle-view">
                  <input
                    type="radio"
                    value="oneProject"
                    id="oneProject"
                    name="toggle"
                    className="toggle toggle-left"
                    onClick={e => this.changeView(e)}
                  />
                  <label
                    htmlFor="oneProject"
                    className="btn-view btn-view-left"
                  >
                    One project
                  </label>
                </div>
                <div className="toggle-view">
                  <input
                    type="radio"
                    value="allProjects"
                    id="allProjects"
                    name="toggle"
                    className="toggle toggle-right"
                    onClick={e => this.changeView(e)}
                    defaultChecked
                  />
                  <label
                    htmlFor="allProjects"
                    className="btn-view btn-view-right"
                  >
                    All projects
                  </label>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="cabinet-content">
          {validator}
          <section className="home-content">
            {view === "allProjects" ? (
              <ProjectCalendar
                schedulerData={viewModel}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                eventItemClick={this.eventClicked}
                updateEventStart={this.updateEventStart}
                updateEventEnd={this.updateEventEnd}
                moveEvent={this.moveEvent}
                movingEvent={this.movingEvent}
                newEvent={this.newEvent}
                subtitleGetter={this.subtitleGetter}
                toggleExpandFunc={this.toggleExpandFunc}
                projects={projects}
                employee={this.state.newEmployees}
              />
            ) : (
              <ViewOneProject
                view={view}
                selectedProject={selectedProject}
                firstSelectProject={firstSelectProject}
                closeLoader={this.closeLoader}
              />
            )}
          </section>
        </div>
        <div className="content-show">
          {show && (
            <CreateEvent
              employees={employees}
              selectedEmployees={selectedEmployees}
              listArr={listArr}
              filterValue={filterValue}
              startDate={startDate}
              endDate={endDate}
              closeCreatePopup={this.closeCreatePopup}
              handleFilterValue={this.handleFilterValue}
              handleSelect={this.handleSelect}
              handleSubmit={this.handleSubmit}
              getUser={this.getUser}
              addListArr={this.addListArr}
              removeEmployee={this.removeEmployee}
            />
          )}
          {showEdit && (
            <EditEvent
              employees={employees}
              selectedEmployees={selectedEmployees}
              listArr={listArr}
              filterValue={filterValue}
              startDate={startDate}
              endDate={endDate}
              closeCreatePopup={this.closeCreatePopup}
              handleFilterValue={this.handleFilterValue}
              handleSelect={this.handleSelect}
              handleEdit={this.handleEdit}
              handleDelete={this.handleDelete}
              getUser={this.getUser}
              addListArr={this.addListArr}
              removeEmployee={this.removeEmployee}
            />
          )}
        </div>
      </React.Fragment>
    );
  }

  isClose = value => {
    this.setState({ open: value });
  };

  getProjectItem = project => {
    if(this.state.selectedProject.id === 'select_project'){
      this.setState({
        selectedProject: project
      });
      this.setState({loading: true})
    } else if(this.state.selectedProject.id !== project.id){
      this.setState({
        selectedProject: project
      });
      this.setState({loading: true})
    }
  };

  changeView = e => {
    this.getEmployee()
    this.setState({
      view: e.target.value,
      selectedProject: { id: "select_project", name: "Select project" },
    });
  };

  handleSelect = range => {
    this.setState({
      startDate: moment(range.startDate._d).format("YYYY-MM-DD HH:mm"),
      endDate: moment(range.endDate._d).format("YYYY-MM-DD HH:mm")
    });
  };

  getUser = employee => {
    if (this.state.selectedEmployees.includes(employee)) return;
    this.setState(
      {
        selectedEmployees: [...this.state.selectedEmployees, employee]
      },
      () => {
        this.addListArr();
      }
    );
  };

  addListArr = () => {
    const { employees } = this.props;
    let { selectedEmployees, listArr } = this.state;
    employees &&
      employees.forEach(element => {
        selectedEmployees.forEach(item => {
          if (element.id === item.id && !listArr.includes(element)) {
            listArr.push(element);
          }
        });
      });
    this.setState({
      listArr
    });
  };

  handleFilterValue = e => {
    this.setState({
      filterValue: e.target.value
    });
  };

  closeCreatePopup = () => {
    this.setState({
      show: false,
      showEdit: false,
      selectedEmployees: [],
      listArr: [],
      startDate: "",
      endDate: "",
      resourceId: ""
    });
  };

  removeEmployee = index => {
    const list = this.state.selectedEmployees;
    list.splice(index, 1);
    this.setState({ selectedEmployees: list, listArr: list });
  };









  getEmployee = () => {
    let newNewEmployees = [];
    database.collection("projects")
      .get()
      .then(project => {
        project.forEach(projectItem => {
          database.collection("projects")
            .doc(projectItem.id)
            .collection("employeesSubcollect")
            .get()
            .then(user => {
              user.forEach(userItem => {
                newNewEmployees.push(userItem.data())
              });
            });
        });
      }).then(() => {
        setTimeout(() => {
          this.setState({
            newEmployees: newNewEmployees
          })
          this.setState({loading: false})
        },800)
      });
  }



closeLoader = (value) => {
  this.setState({loading: value})
}









  handleSubmit = async e => {
    e.preventDefault();
    let startProjectDate = new Date(this.state.startDate);
    startProjectDate.setHours(0);
    startProjectDate.setMinutes(0);
    startProjectDate.setSeconds(1);
    let endProjectDate = new Date(this.state.endDate);
    let employeeArray = this.state.listArr;

    let trueArr = [];
    employeeArray.forEach(item => {
      let startVacation = new Date(item.start);
      let endVacation = new Date(item.end);
      if (
        startProjectDate.getTime() >= startVacation.getTime() &&
        startProjectDate.getTime() <= endVacation.getTime()
      ) {
        this.setState({
          vacationName: item.title,
          vacationSurname: item.lastName,
          open: true
        });
        trueArr.push(true);
        return;
      } else if (
        endProjectDate.getTime() <= endVacation.getTime() &&
        endProjectDate.getTime() >= startVacation.getTime()
      ) {
        this.setState({
          vacationName: item.title,
          vacationSurname: item.lastName,
          open: true
        });
        trueArr.push(true);
        return;
      } else if (
        startProjectDate.getTime() <= startVacation.getTime() &&
        endProjectDate.getTime() >= endVacation.getTime()
      ) {
        this.setState({
          vacationName: item.title,
          vacationSurname: item.lastName,
          open: true
        });
        trueArr.push(true);
        return;
      } else {
        trueArr.push(false);
      }
    });

    let isVacationTrue = trueArr.find(item => item === true);
    if (isVacationTrue === undefined) {
      this.setState({loading: true})
      const response = await this.props.addEmployeeCalendar(this.state);
        setTimeout(() => {
          this.getEmployee()
        }, 1000)
      this.setState({
        ...this.state,
        show: !this.state.show,
        resourceId: "",
        selectedEmployees: [],
        listArr: [],
        startDate: "",
        endDate: "",
        update: true,
        hasUpdated: !this.state.hasUpdated
      },() => { 
      });
    } else {
      this.setState({
        isValid: false
      });
    }
  };

  handleDelete = (resourceId, employeeId) => {
    this.setState({loading: true})
    this.props.deleteEmployeeInProject(resourceId, employeeId);
    this.getEmployee()
    this.setState({
      showEdit: !this.state.showEdit,
      resourceId: "",
      selectedEmployees: [],
      startDate: "",
      endDate: ""
    });
  };
















  handleEdit = e => {
    e.preventDefault();
    this.props.editEmployeeCalendar(this.state);
    this.setState({ showEdit: !this.state.showEdit, resourceId: "" });
  };

  prevClick = schedulerData => {
    schedulerData.prev();
    schedulerData.setEvents(DemoData.eventsForTaskView);
    this.setState({
      viewModel: schedulerData
    });
  };

  nextClick = schedulerData => {
    schedulerData.next();
    schedulerData.setEvents(DemoData.eventsForTaskView);
    this.setState({
      viewModel: schedulerData
    });
  };

  onViewChange = (schedulerData, view) => {
    console.log("onViewChange");
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    this.setState({
      viewModel: schedulerData
    });
  };

  onSelectDate = (schedulerData, date) => {
    console.log("onSelectDate");
    schedulerData.setDate(date);
    schedulerData.setEvents(DemoData.eventsForTaskView);
    this.setState({
      viewModel: schedulerData
    });
  };

  eventClicked = (schedulerData, event) => {
    console.log("eventClicked");
    this.setState({
      showEdit: true,
      selectedEmployees: event,
      resourceId: event.resourceId
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    this.setState({
      show: true,
      resourceId: slotId,
      slotName: slotName,
      startDate: start,
      endDate: start
    });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    this.setState({
      ...this.state,
      showEdit: false
    });

    let startInProject = new Date(event.start);
    startInProject.setHours(0);
    startInProject.setMinutes(0);
    startInProject.setSeconds(1);
    startInProject.getTime();
    let endInProject = new Date(event.end).getTime();

    let startVacation = null;
    let finishVacation = null;

    let newStartDate = new Date(newStart);
    database
      .collection("employees")
      .doc(event.id)
      .get()
      .then(snap => {
        startVacation = new Date(snap.data().start).getTime();
        finishVacation = new Date(snap.data().end).getTime();
        if (
          newStartDate.getTime() >= startVacation &&
          newStartDate.getTime() <= finishVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        } else if (
          endInProject <= finishVacation &&
          endInProject >= startVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        } else if (
          newStartDate.getTime() <= startVacation &&
          endInProject >= finishVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        }
        schedulerData.updateEventStart(event, newStart);
        this.props.moveStartDate(event, newStart);
        this.setState({
          viewModel: schedulerData
        });
      });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    this.setState({
      ...this.state,
      showEdit: false
    });
    let startInProject = new Date(event.start);
    startInProject.setHours(0);
    startInProject.setMinutes(0);
    startInProject.setSeconds(1);
    startInProject.getTime();

    let startVacation = null;
    let finishVacation = null;

    let newEndDate = new Date(newEnd);

    database
      .collection("employees")
      .doc(event.id)
      .get()
      .then(snap => {
        startVacation = new Date(snap.data().start).getTime();
        finishVacation = new Date(snap.data().end).getTime();

        if (
          startInProject.getTime() >= startVacation &&
          startInProject <= finishVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        } else if (
          newEndDate.getTime() <= finishVacation &&
          newEndDate.getTime() >= startVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        } else if (
          startInProject.getTime() <= startVacation &&
          newEndDate.getTime() >= finishVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        }
        schedulerData.updateEventEnd(event, newEnd);
        this.props.moveEndDate(event, newEnd);
        this.setState({
          viewModel: schedulerData
        });
      });
  };

  moveEvent = async (schedulerData, event, slotId, slotName, start, end) => {
    this.setState({
      ...this.state,
      showEdit: false
    });
    let startInProject = new Date(start);
    let endInProject = new Date(end);

    let startVacation = null;
    let finishVacation = null;

    database
      .collection("employees")
      .doc(event.id)
      .get()
      .then(async snap => {
        startVacation = new Date(snap.data().start).getTime();
        finishVacation = new Date(snap.data().end).getTime();
        if (
          startInProject.getTime() >= startVacation &&
          startInProject.getTime() <= finishVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        } else if (
          endInProject <= finishVacation &&
          endInProject >= startVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        } else if (
          startInProject.getTime() <= startVacation &&
          endInProject >= finishVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        }
        const previousId = await event.resourceId;
        schedulerData.moveEvent(event, slotId, slotName, start, end);
        this.props.moveEmployee(
          previousId,
          event,
          slotId,
          slotName,
          start,
          end
        );
        this.setState({
          viewModel: schedulerData
        });
      });
  };

  subtitleGetter = (schedulerData, event) => {
    return schedulerData.isEventPerspective
      ? schedulerData.getResourceById(event.resourceId).name
      : event.groupName;
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };
}

const mapStateToProps = state => {
  return {
    projects: state.firestore.ordered.projects,
    employees: state.firestore.ordered.employees
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addEmployeeCalendar: (slotId, slotName, start, end, item) =>
      dispatch(addEmployeeCalendar(slotId, slotName, start, end, item)),
    moveEmployee: (previousId, event, slotId, slotName, start, end) =>
      dispatch(moveEmployee(previousId, event, slotId, slotName, start, end)),
    moveStartDate: (event, newStart) =>
      dispatch(moveStartDate(event, newStart)),
    moveEndDate: (event, newEnd) => dispatch(moveEndDate(event, newEnd)),
    editEmployeeCalendar: editEmployee =>
      dispatch(editEmployeeCalendar(editEmployee)),
    deleteEmployeeInProject: (projectId, employeeId) =>
      dispatch(deleteEmployeeInProject(projectId, employeeId))
  };
};

const home = withDragDropContext(Home);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "projects" }, { collection: "employees" }])
)(home);
