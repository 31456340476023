import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyCSQwx242FJyDsuVUxDD4QHoCImonRIdp4",
//   authDomain: "dipart-test.firebaseapp.com",
//   databaseURL: "https://dipart-test.firebaseio.com",
//   projectId: "dipart-test",
//   storageBucket: "dipart-test.appspot.com",
//   messagingSenderId: "190506023360",
//   appId: "1:190506023360:web:014caa0f0c671db57eb025",
//   measurementId: "G-G1924QD2LT"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDL32wgPVvULcCNHrtBlcsyjTsEVYnY93g",
  authDomain: "dipart-20ebe.firebaseapp.com",
  databaseURL: "https://dipart-20ebe.firebaseio.com",
  projectId: "dipart",
  storageBucket: "dipart.appspot.com",
  messagingSenderId: "341408114953",
  appId: "1:341408114953:web:be522ab816002f49a54bd8",
  measurementId: "G-GVX54JRS97"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({});

const storage = firebase.storage();

const database = firebase.firestore();

export { database, storage, firebase as default };
