import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import moment from "moment";
import { Col, Row } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import CreateEvent from "./CreateEvent";
import EditOneEvent from "./EditOneEvent";
import TransitionsModal from "../Popup/Validator";
import { database } from "../../firebase";

import {
  addEmployeeOneProject,
  dropOneProject,
  editEmployeeOneCalendar,
  deleteEmployeeInProject
} from "../../store/action/projectActions";

class Home extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      editVacation: false,
      show: false,
      showEdit: false,
      startDate: "",
      endDate: "",
      selectedEmployees: [],
      listArr: [],
      filterValue: "",
      resourceId: "",
      isValid: true,
      vacationName: "",
      vacationSurname: "",
      open: true,
      update: false,
      employees: [],
      handleDate: ''
    };
  }

  render() {
    const { employee, employees, selectedProject } = this.props;
    const {
      show,
      startDate,
      endDate,
      filterValue,
      selectedEmployees,
      listArr,
      showEdit
    } = this.state;

    const validator = !this.state.isValid ? (
      <TransitionsModal
        name={this.state.vacationName}
        surname={this.state.vacationSurname}
        open={this.state.open}
        isClose={this.isClose}
      />
    ) : null;

    return (
      <React.Fragment>
        {validator}
        {selectedProject.id === "select_project" ? (
          <Row>
            <Col lg={9} sm={9} md={9}>
              <FullCalendar
                defaultView="dayGridMonth"
                theme={true}
                header={{
                  left: "prev, title, next",
                  center: "",
                  right: ""
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                firstDay={1}
                ref={this.calendarComponentRef}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={9} sm={9} md={9}>
              <FullCalendar
                defaultView="dayGridMonth"
                theme={true}
                header={{
                  left: "prev, title, next",
                  center: "",
                  right: ""
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                firstDay={1}
                rerenderDelay={10}
                editable={true}
                selectable={true}
                droppable={true}
                overlap={true}
                create={true}
                duration={true}
                eventLimit={true}
                forceEventDuration={true}
                ref={this.calendarComponentRef}
                events={this.state.employees}
                eventDrop={this.eventDrop}
                eventRender={this.eventRender}
                drop={this.drop}
                resize={true}
                datesRender={this.datesRender}
                eventReceive={this.eventReceive}
                eventResize={this.eventResize}
                eventClick={this.eventClick}
                dateClick={this.handleDateClick}
              />
            </Col>
          </Row>
        )}

        {show && (
          <CreateEvent
            employees={employees}
            selectedEmployees={selectedEmployees}
            listArr={listArr}
            filterValue={filterValue}
            handleFilterValue={this.handleFilterValue}
            startDate={startDate}
            endDate={endDate}
            handleSelect={this.handleSelect}
            closeCreatePopup={this.closeCreatePopup}
            handleSubmit={this.handleSubmit}
            getUser={this.getUser}
            addListArr={this.addListArr}
            removeEmployee={this.removeEmployee}
          />
        )}

        {showEdit && (
          <EditOneEvent
            employees={employees}
            selectedEmployees={selectedEmployees}
            selectedProject={selectedProject}
            listArr={listArr}
            filterValue={filterValue}
            closeCreatePopup={this.closeCreatePopup}
            handleFilterValue={this.handleFilterValue}
            startDate={startDate}
            endDate={endDate}
            handleSelect={this.handleSelect}
            handleEdit={this.handleEdit}
            getUser={this.getUser}
            addListArr={this.addListArr}
            removeEmployee={this.removeEmployee}
            handleDelete={this.handleDelete}
            handleDate = {this.state.handleDate}
          />
        )}
      </React.Fragment>
    );
  }

  isClose = value => {
    this.setState({ open: value });
  };

  eventClick = info => {
    this.setState({
      showEdit: true,
      selectedEmployees: info.event.id,
      resourceId: this.props.selectedProject.id,
      handleDate: info.event
    });
  };

  eventDrop = info => {
    let startDrop = moment(info.event.start).format("YYYY-MM-DD HH:mm");
    let endDrop = moment(info.event.end).format("YYYY-MM-DD HH:mm");

    console.log("HERE DROP EMPLOYEE");

    this.setState({
      ...this.state,
      showEdit: false
    });

    let employeeID = info.event.id;

    let startVacation = null;
    let finishVacation = null;

    let startInProject = new Date(startDrop).getTime();
    let endInProject = new Date(endDrop).getTime();

    database
      .collection("employees")
      .doc(employeeID)
      .get()
      .then(snap => {
        startVacation = new Date(snap.data().start).getTime();
        finishVacation = new Date(snap.data().end).getTime();
        if (
          startInProject >= startVacation &&
          startInProject <= finishVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        } else if (
          endInProject <= finishVacation &&
          endInProject >= startVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        } else if (
          startInProject <= startVacation &&
          endInProject >= finishVacation
        ) {
          this.setState({
            vacationName: snap.data().firstName,
            vacationSurname: snap.data().lastName,
            isValid: false,
            open: true
          });
          return;
        }
        this.props.dropOneProject(
          this.props.selectedProject,
          info.event.id,
          startDrop,
          endDrop
        );
      });
  };

  eventReceive = info => {
    console.log("eventReceive");
    this.setState({
      eventReceiveStartDate: moment(info.event.start).format("YYYY-MM-DD")
    });
  };

  eventResize = info => {
    console.log(info);
  };

  eventRender = info => {
    console.log(info);
  };

  handleDateClick = arg => {
    this.setState({
      show: true,
      startDate: arg.date,
      endDate: arg.date
    });
  };

  closeCreatePopup = () => {
    this.setState({
      show: false,
      showEdit: false,
      selectedEmployees: [],
      listArr: [],
      startDate: "",
      endDate: ""
    });
  };

  getUser = employee => {
    if (this.state.selectedEmployees.includes(employee)) return;
    this.setState(
      {
        selectedEmployees: [...this.state.selectedEmployees, employee]
      },
      () => {
        this.addListArr();
      }
    );
  };

  addListArr = () => {
    const { employees } = this.props;
    let { selectedEmployees, listArr } = this.state;
    employees &&
      employees.forEach(element => {
        selectedEmployees.forEach(item => {
          if (element.id === item.id && !listArr.includes(element)) {
            listArr.push(element);
          }
        });
      });
    this.setState({
      listArr
    });
  };

  handleFilterValue = e => {
    this.setState({
      filterValue: e.target.value
    });
  };

  removeEmployee = index => {
    const list = this.state.selectedEmployees;
    list.splice(index, 1);
    this.setState({ selectedEmployees: list, listArr: list });
  };

  handleSelect = range => {
    this.setState({
      startDate: moment(range.startDate._d).format("YYYY-MM-DD HH:mm"),
      endDate: moment(range.endDate._d).format("YYYY-MM-DD HH:mm")
    });
  };
  handleEdit = e => {
    e.preventDefault();
    this.props.editEmployeeOneCalendar(this.state);
    setTimeout(() => {
      this.getEmployee();
    }, 1000);
    this.setState({ showEdit: !this.state.showEdit, resourceId: "" });
  };










  componentDidMount() {
    this.getEmployee();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.selectedProject.id !== this.props.selectedProject.id){
      this.getEmployee()
    }
  }

  getEmployee = () => {  
    let employeeArr = [];
    database
      .collection("projects")
      .doc(this.props.selectedProject.id)
      .collection("employeesSubcollect")
      .get()
      .then(snap => {
        snap.docs.forEach(userItem => {
          employeeArr.push(userItem.data())
        });
      })
      .then(() => {
        setTimeout(() => {
          this.setState({
            employees: employeeArr
          });
          this.props.closeLoader(false)
        },800);
      })
  };

  handleSubmit = async e => {
    console.log("HERE CREATE EMPLOYEE");
    e.preventDefault();

    let startProjectDate = new Date(this.state.startDate);
    let endProjectDate = new Date(this.state.endDate);
    let employeeArray = this.state.listArr;

    let trueArr = [];

    employeeArray.forEach(item => {
      let startVacation = new Date(item.start);
      let endVacation = new Date(item.end);
      if (
        startProjectDate.getTime() >= startVacation.getTime() &&
        startProjectDate.getTime() <= endVacation.getTime()
      ) {
        this.setState({
          vacationName: item.title,
          vacationSurname: item.lastName,
          open: true
        });
        trueArr.push(true);
        return;
      } else if (
        endProjectDate.getTime() <= endVacation.getTime() &&
        endProjectDate.getTime() >= startVacation.getTime()
      ) {
        this.setState({
          vacationName: item.title,
          vacationSurname: item.lastName,
          open: true
        });
        trueArr.push(true);
        return;
      } else if (
        startProjectDate.getTime() <= startVacation.getTime() &&
        endProjectDate.getTime() >= endVacation.getTime()
      ) {
        this.setState({
          vacationName: item.title,
          vacationSurname: item.lastName,
          open: true
        });
        trueArr.push(true);
        return;
      } else {
        trueArr.push(false);
      }
    });

    let isVacationTrue = trueArr.find(item => item === true);
    if (isVacationTrue === undefined) {
      this.props.closeLoader(true)
      const response = await this.props.addEmployeeOneProject(
        this.props.selectedProject,
        this.state
      );
      setTimeout(() => {
        this.getEmployee();
      }, 1000);
      this.setState({
        ...this.state,
        show: !this.state.show,
        selectedEmployees: [],
        listArr: [],
        startDate: "",
        endDate: "",
        update: true
      });
    } else {
      this.setState({
        isValid: false
      });
    }
  };

















  handleDelete = (resourceId, employeeId) => {
    this.props.closeLoader(true)
    this.props.deleteEmployeeInProject(resourceId, employeeId);
    setTimeout(() => {
      this.getEmployee();
    }, 1000);
    this.setState({
      showEdit: !this.state.showEdit,
      resourceId: "",
      selectedEmployees: [],
      startDate: "",
      endDate: ""
    });
  };
}

const mapStateToProps = state => {
  return {
    projects: state.firestore.ordered.projects,
    employees: state.firestore.ordered.employees
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addEmployeeOneProject: (selectedProject, employee) =>
      dispatch(addEmployeeOneProject(selectedProject, employee)),
    dropOneProject: (selectedProject, id, startDrop, endDrop) =>
      dispatch(dropOneProject(selectedProject, id, startDrop, endDrop)),
    editEmployeeOneCalendar: editEmployee =>
      dispatch(editEmployeeOneCalendar(editEmployee)),
    deleteEmployeeInProject: (projectId, employeeId) =>
      dispatch(deleteEmployeeInProject(projectId, employeeId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "projects" }, { collection: "employees" }])
)(Home);
