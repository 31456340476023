import React from "react";
import Sidebar from "./../containers/SideBar";
const CabinetWrapper = ({ children } = this.props) => {
  return (
    <div className="cabinet">
      <Sidebar />
      <main className="cabinet-main">{children}</main>
    </div>
  );
};

export default CabinetWrapper;
