import moment from "moment";

export const createEmployee = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firebase = getFirebase();
    const firestore = getFirestore();

    let user_id = "";
    let projectName =
      newUser.selectedProject.length > 0 ? newUser.selectedProject.name : "";
    let resourceId = newUser.resourceId ? newUser.resourceId : "";

    const collect = firestore.collection("employees");
		
    collect
      .add({
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        position: newUser.position,
        phone: newUser.phone,
        project: projectName,
        imgName: newUser.picture,
        picture: newUser.pictureUrl,
        email: newUser.email,
        password: newUser.password,
        createAuth: newUser.createAuth,
        start: "",
        end: "",
        title: newUser.firstName,
        uid: ''
      })
      .then(resp => {
        user_id = resp.id;
        collect.doc(resp.id).update({
          id: resp.id
        });
      })

      .then(() => {
        collect
          .doc(user_id)
          .collection("projectsSubcollect")
          .doc(newUser.selectedProject.id)
          .set({
            id: newUser.selectedProject.id,
            name: newUser.selectedProject.name,
            status: newUser.selectedProject.status
          });

        firestore
          .collection("projects")
          .doc(newUser.selectedProject.id)
          .collection("employeesSubcollect")
          .doc(user_id)
          .set({
            id: user_id,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            position: newUser.position,
            phone: newUser.phone,
            project: newUser.project,
            picture: newUser.pictureUrl,
            start: "",
            end: "",
            resourceId: resourceId,
            title: newUser.firstName
          });
      })

      .then(() => {
        dispatch({ type: "CREATE_EMPLOYEE" });
      })
      .catch(err => {
        dispatch({ type: "CREATE_EMPLOYEE_ERROR", err });
      });
  };
};

export const updateEmployee = (
  firstName,
  lastName,
  phone,
  position,
  project,
  pictureUrl,
  id
) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const collect = firestore.collection("employees");

    collect
      .doc(id)
      .update({
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        position: position,
        picture: pictureUrl,
        title: firstName
      })

      .then(() => {
        dispatch({ type: "UPDATE_EMPLOYEE_SUCCESS", id });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_EMPLOYEE_ERROR", err });
      });
  };
};

export const deleteEmployee = id => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    let collect = firestore.collection("employees").doc(id);

    collect
      .collection("projectsSubcollect")
      .get()
      .then(snap => {
        snap.forEach(elem => {
          collect
            .collection("projectsSubcollect")
            .doc(elem.id)
            .delete();
        });
      });
    collect.delete();

    firestore
      .collection("projects")
      .get()
      .then(snap => {
        snap.forEach(elem => {
          firestore
            .collection("projects")
            .doc(elem.id)
            .collection("employeesSubcollect")
            .doc(id)
            .delete();
        });
      })

      .then(() => {
        dispatch({ type: "DELETE_EMPLOYEE_SUCCESS", id });
      })
      .catch(err => {
        dispatch({ type: "DELETE_EMPLOYEE_ERROR", err });
      });
  };
};

export const addEmployeeVacation = vacation => (
  dispatch,
  getState,
  { getFirestore }
) => {

  let start = new Date(vacation.startDate)
  start.setHours(0)
  start.setMinutes(0)
  let end = new Date(vacation.endDate)
  end.setHours(23)
  end.setMinutes(59)
  let END = moment(end)
  let START = moment(start)
  console.log('startDate: ', vacation.startDate);
  console.log('endDate: ', vacation.endDate);
  console.log('endDate-Moment: ', moment(END).format("YYYY-MM-DD HH:mm"));
  console.log('startDate-Moment: ', moment(START).format("YYYY-MM-DD HH:mm"));


  const firestore = getFirestore();
  const collect = firestore.collection("employees");
  collect
    .get()
    .then(() => {
      vacation.selectedEmployees.forEach(employee => {
        collect.doc(employee.id).update({
          start: moment(START).format("YYYY-MM-DD HH:mm"),
          end: moment(END).format("YYYY-MM-DD HH:mm")
        });
      });
    })
    .then(() => {
      dispatch({ type: "ADD_VACATION_SUCCESS", vacation });
    })
    .catch(err => {
      dispatch({ type: "ADD_VACATION_ERROR", err });
    });
};

export const dropVacation = (id, startDrop, endDrop) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("employees")
      .doc(id)
      .update({
        start: startDrop,
        end: endDrop
      })
      .then(() => {
        dispatch({ type: "DROP_VACATION_SUCCESS", id });
      })
      .catch(err => {
        dispatch({ type: "DROP_VACATION_ERROR", err });
      });
  };
};

export const deleteVacation = id => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("employees")
      .doc(id)
      .update({
        start: "",
        end: ""
      })
      .then(() => {
        dispatch({ type: "DELETE_VACATION_SUCCESS", id });
      })
      .catch(err => {
        dispatch({ type: "DELETE_VACATION_ERROR", err });
      });
  };
};

export const editEmployeeVacation = editEmployee => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const collect = firestore.collection("employees");
    collect
      .doc(editEmployee.selectedEmployees)
      .update({
        start: editEmployee.startDate,
        end: editEmployee.endDate
      })
      .then(() => {
        dispatch({ type: "EDIT_EMPLOYEE_SUCCESS", editEmployee });
      })
      .catch(err => {
        dispatch({ type: "EDIT_EMPLOYEE_ERROR", err });
      });
  };
};
