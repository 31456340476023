import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import {database} from '../firebase';
import { Container, Draggable } from "react-smooth-dnd";
import {
  createProject,
  updateProject,
  deleteProject,
  deleteSelectProject,
  deleteEmployeeInProject
} from "../store/action/projectActions";

import AddPopupProject from "../components/Popup/AddProject";
import Project from "../components/Project";

import iconDeleteProject from "../assets/projects/delete.svg";
import iconAdd from "../assets/icons/add.svg";
import "array.prototype.move";
import Loader from "../components/Loader/Loader";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: true,
      showPopupProject: false,
      name: "",
      workers: "",
      status: "",
      selectedEmployees: [],
      listArr: [],
      selectedProject: new Set(),
      checked: false,
      isAllchecked: false,
      projects: [],
      loading: false,
    };
     this.uniqueArray = [];
  }

  toggleMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  togglePopup = () => {
    this.setState({ showPopupProject: !this.state.showPopupProject });
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  getUserId = someValue => {
    if (this.state.selectedEmployees.includes(someValue)) return;
    this.setState(
      {
        selectedEmployees: [...this.state.selectedEmployees, someValue]
      },
      () => {
        this.addListArr();
      }
    );
  };

  addListArr = () => {
    const { employees } = this.props;
    let { selectedEmployees, listArr } = this.state;
    employees &&
      employees.forEach(element => {
        selectedEmployees.forEach(item => {
          if (element.id === item.id && !listArr.includes(element)) {
            listArr.push(element);
          }
        });
      });
    this.setState({
      listArr
    });
  };

  changeStatus = status => {
    this.setState({
      status: status
    });
  };

  handleDelete = (e, id) => {
    e.preventDefault();
    this.props.deleteProject(id);
  };

  handleDeleteSelected = selectedProject => {
    this.props.deleteSelectProject(selectedProject);
    this.setState({
      isAllchecked: false,
      checked: false,
      selectedProject: new Set()
    });
  };

  handleSelectedProject = (
    allSelected,
    individualSelected,
    selecteProject,
    checked
  ) => {
    let selectedProject = this.state.selectedProject;
    if (allSelected && !individualSelected) {
      this.props.projects &&
        this.props.projects.forEach(project => {
          selectedProject.add(project.id);
        });
    } else if (!allSelected && !individualSelected) {
      selectedProject.clear();
    } else if (individualSelected) {
      if (checked) {
        selectedProject.add(selecteProject);
        if (
          selectedProject.size === this.props.projects &&
          this.props.projects.length
        ) {
          this.checkAll();
        }
      } else {
        selectedProject.delete(selecteProject);
        this.setState({ checked });
      }
    }
    this.setState({ selectedProject });
  };

  checkAll = () => {
    this.setState({ checked: !this.state.checked });
    this.handleSelectedProject(!this.state.checked, false);
  };

  removeEmployee = index => {
    const list = this.state.selectedEmployees;
    list.splice(index, 1);
    this.setState({ selectedEmployees: list, listArr: list });
  };

  handleUpdate = (e, id) => {
    e.preventDefault();
    let name = e.target.name.value;
    let employee = this.state.listArr;
    let status = this.state.status;
    this.props.updateProject(name, id, employee, status);
    this.setState({
      listArr: [],
      selectedEmployees: [],
      status: ""
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.createProject(this.state);
    this.setState({
      showPopupProject: !this.state.showPopupProject,
      listArr: [],
      selectedEmployees: []
    });
  };

  onDrop = dropResult => {
    const { removedIndex, addedIndex, payload, element } = dropResult;
    let projects = this.props.projects;
    projects.move(removedIndex, addedIndex);
    // this.setState({loading: true})
    database.collection('projects').get().then(snap => {
      snap.forEach(project => {
        let ProjectId = project.data().id;
        projects.forEach((item, index) => {
          if(ProjectId === item.id){
           console.log('itemPosition: ',  item.position, index);
           database.collection('projects').doc(ProjectId).update({
             position: index
           }) 
          }
        })
        // this.setState({loading: false})
      })
    })

    console.log("removedIndex: ", removedIndex);
    console.log("addedIndex: ", addedIndex);
    console.log("projects: ", projects);
  };
  render() {
    // const loader = this.state.loading ? <Loader/> : null
    const {
      showPopupProject,
      selectedEmployees,
      listArr,
      isAllchecked,
      checked,
      selectedProject,
      name
    } = this.state;
    const { projects, employees, deleteEmployeeInProject } = this.props;
    const uniqueArray = [...new Set(projects)];
    return (
      <React.Fragment>
        <header className="cabinet-header">
          <div className="cabinet-header-block">
            <div>
              <p className="header-title">Projects</p>
            </div>
            <div className="header-panel">
              <button
                onClick={this.togglePopup}
                className="add-button add-button-project"
              >
                Add project
                <img src={iconAdd} alt="add" />
              </button>
            </div>
          </div>
        </header>
        <div className="cabinet-content">
          <section className="content-project">
            <div className="table-project">
              <div className="custom-div__header">
                <p className="custom-table__heading custom-checkbox custom-checkbox-header">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={this.checkAll}
                    value="all"
                    name="checkAll"
                  />
                </p>
                <p className="custom-table__heading">NAME</p>
                <p className="custom-table__heading">WORKERS</p>
                <p className="custom-table__heading">STATUS</p>
                {selectedProject.size < 2 ? (
                  <p className="custom-table__heading">MANAGE</p>
                ) : (
                  <p className="custom-table__heading delete-project">
                    <img
                      src={iconDeleteProject}
                      alt="delete"
                      onClick={() => this.handleDeleteSelected(selectedProject)}
                    />
                  </p>
                )}
              </div>
              {/* {loader} */}
              <Container onDrop={this.onDrop}>
                {uniqueArray
                  && uniqueArray.sort((a, b) => (a.position > b.position) ? 1 : -1)
                  .map(project => (
                    <Draggable key={project.id}>
                      <Project
                        project={project}
                        employees={employees}
                        getUserId={this.getUserId}
                        selectedEmployees={selectedEmployees}
                        listArr={listArr}
                        addListArr={this.addListArr}
                        deleteEmployeeInProject={deleteEmployeeInProject}
                        handleDelete={this.handleDelete}
                        handleUpdate={this.handleUpdate}
                        removeEmployee={this.removeEmployee}
                        isAllchecked={isAllchecked}
                        checked={checked}
                        selectedProject={selectedProject}
                        handleSelectedProject={this.handleSelectedProject}
                        changeStatus={this.changeStatus}
                      />
                    </Draggable>
                  ))}
              </Container>
            </div>
          </section>
        </div>
        {showPopupProject && (
          <AddPopupProject
            projectPopup={this.togglePopup}
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            handleMultiChange={this.handleMultiChange}
            employees={employees}
            selectedEmployees={selectedEmployees}
            getUserId={this.getUserId}
            listArr={listArr}
            addListArr={this.addListArr}
            removeEmployee={this.removeEmployee}
            name={name}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.firestore.ordered.projects,
    employees: state.firestore.ordered.employees
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createProject: project => dispatch(createProject(project)),
    deleteProject: id => dispatch(deleteProject(id)),
    deleteSelectProject: selectedProject =>
      dispatch(deleteSelectProject(selectedProject)),
    deleteEmployeeInProject: (projectId, employeeId) =>
      dispatch(deleteEmployeeInProject(projectId, employeeId)),
    updateProject: (name, id, employee, status) =>
      dispatch(updateProject(name, id, employee, status))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => {
    return [{ collection: "projects" }, { collection: "employees" }];
  })
)(Projects);
