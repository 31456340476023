import React, { useState, useRef, useEffect } from "react";
import closePopup from "../../assets/employees/closePopup.svg";
import PreviewPicture from "./PreviewPicture";
import uploadPhoto from "../../assets/employees/upload_photo.svg";
import ListPosition from "./ListPosition";

export const EditEmployeePopup = (
  {
    toggleEdit,
    handleChange,
    handlePicture,
    pictureUrl,
    employee,
    handleUpdate,
    position,
    getPositionItem
  } = this.props
) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangePopup = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div className="popup">
      <div className="popup_inner employee-popup">
        <img
          onClick={toggleEdit}
          src={closePopup}
          alt="close"
          className="button-close-popup"
        />
        <form
          onSubmit={e => {
            handleUpdate(e, employee.id, employee.position);
            toggleEdit();
          }}
          className="employee-content"
        >
          <div className="employee-content-form">
            <h1 className="popup-title">Edit employee</h1>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--employee"
                type="text"
                name="firstName"
                placeholder="Firstname"
                id="firstName"
                onChange={handleChange}
                defaultValue={employee.firstName}
                maxLength={20}
              />
            </div>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--employee"
                type="text"
                name="lastName"
                placeholder="Surname"
                id="lastName"
                onChange={handleChange}
                defaultValue={employee.lastName}
                maxLength={20}
              />
            </div>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--employee"
                type="phone"
                name="phone"
                placeholder="Phone number"
                id="phone"
                onChange={e => handleChange(e)}
                defaultValue={employee.phone}
                maxLength={20}
              />
            </div>
            <div className="custom-input__group" ref={node}>
              <div
                className="custom-input custom-input--employee"
                onClick={e => setOpen(!open)}
              >
                <p
                  id="custom-label"
                  className="project-size project-size-select"
                >
                  {position === "" ? employee.position : position}
                </p>
                <div
                  className={open ? "arrow-down arrow-rotate" : "arrow-down"}
                ></div>
              </div>
              {open && (
                <ListPosition
                  getPositionItem={getPositionItem}
                  togglePosition={handleChangePopup}
                />
              )}
            </div>
          </div>
          <div className="employee-content-photo">
            {pictureUrl === null ? (
              <img
                src={employee.picture === null ? uploadPhoto : employee.picture}
                alt={employee.lastName}
                className="preview-img"
              />
            ) : (
              <PreviewPicture pictureUrl={pictureUrl} />
            )}
            <label className="custom-file-upload">
              <input
                type="file"
                onChange={handlePicture}
                name="image"
                id="image"
              />
              <p className="upload-button">Upload photo</p>
            </label>
            <p className="upload-text">Upload a profile picture (JPG or PNG)</p>
          </div>
          <button className="add-button">Save</button>
        </form>
      </div>
    </div>
  );
};

export default EditEmployeePopup;
