const initState = {};

const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_PROJECT":
      console.log("create project", action.project);
      return {
        ...state
      };
    case "CREATE_PROJECT_ERROR":
      console.log("create project error", action.err);
      return state;

    case "UPDATE_PROJECT_SUCCESS":
      console.log("update", action.id);
      return state;
    case "UPDATE_PROJECT_ERROR":
      console.log("update project error", action.err);
      return state;

    case "ADD_EMPLOYEE_SUCCESS":
      console.log("add employee in project", action.item);
      return state;
    case "ADD_EMPLOYEE_ERROR":
      console.log("add employee in project error", action.err);
      return state;

    case "EDIT_EMPLOYEE_SUCCESS":
      console.log("EDIT employee in project", action.editEmployee);
      return state;
    case "EDIT_EMPLOYEE_ERROR":
      console.log("EDIT employee in project error", action.err);
      return state;


    case "ADD_EMPLOYEE_ONEPROJECT_SUCCESS":
      console.log("add employee in project", action.item);
      return state;
    case "ADD_EMPLOYEE_ONEPROJECT_ERROR":
      console.log("add employee in project error", action.err);
      return state;

    case "MOVE_EMPLOYEE_SUCCESS":
      console.log("move", action.event);
      return state;
    case "MOVE_EMPLOYEE_ERROR":
      console.log("move project error", action.err);
      return state;

    case "MOVE_ONE_PROJECT_SUCCESS":
      console.log("move", action.event);
      return state;
    case "MOVE_ONE_PROJECT_ERROR":
      console.log("move project error", action.err);
      return state;

    case "START_DATE_SUCCESS":
      console.log("start date", action.event);
      return state;
    case "START_DATE_ERROR":
      console.log("start date project error", action.err);
      return state;

    case "END_DATE_SUCCESS":
      console.log("end date", action.event);
      return state;
    case "END_DATE_ERROR":
      console.log("end date project error", action.err);
      return state;

    case "DELETE_PROJECT_SUCCESS":
      console.log("deleted", action.id);
      return state;
    case "DELETE_PROJECT_ERROR":
      console.log("delete project error", action.err);
      return state;

    case "DELETE_EMPLOYEEINPROJECT_SUCCESS":
      console.log("deleted", action.employeeId);
      return state;
    case "DELETE_EMPLOYEEINPROJECT_ERROR":
      console.log("delete employee error", action.err);
      return state;

    default:
      return state;
  }
};

export default projectReducer;
