import React from "react";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import "antd/lib/grid/style/index.css";
import Scheduler from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";

const ProjectCalendar = (
  {
    schedulerData,
    prevClick,
    nextClick,
    onSelectDate,
    onViewChange,
    eventItemClick,
    updateEventStart,
    updateEventEnd,
    moveEvent,
    movingEvent,
    newEvent,
    subtitleGetter,
    toggleExpandFunc,
    projects,
    employee,
  } = this.props
) => {

  schedulerData.setResources(
    projects && projects.length > 0
      ? projects.sort((a, b) => (a.position > b.position ? 1 : -1))
      : []
  );

  
  schedulerData.setEvents(employee && employee.length > 0 ? employee : [])

  return (
    <Row>
      <Col span={40}>
        <Scheduler
          schedulerData={schedulerData}
          prevClick={prevClick}
          nextClick={nextClick}
          onSelectDate={onSelectDate}
          onViewChange={onViewChange}
          eventItemClick={eventItemClick}
          updateEventStart={updateEventStart}
          updateEventEnd={updateEventEnd}
          moveEvent={moveEvent}
          movingEvent={movingEvent}
          newEvent={newEvent}
          subtitleGetter={subtitleGetter}
          toggleExpandFunc={toggleExpandFunc}
        />
      </Col>
    </Row>
  );
};

export default ProjectCalendar;
