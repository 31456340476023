import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import vacationBigImg from "../../assets/icons/Group.png";
import { Redirect } from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: "25px",
    paddingRight: "25px",
    borderRadius: "25px",
    backgroundColor: "#FB4D4F",
    color: "white"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "260px",
    width: "390px",
    borderRadius: "2px",
    display: "flex",
    alignItems: "center"
  }
}));
const style = {
  display: "flex",
  alignItems: "center",
  jastifyContent: "space-between",
  flexDirection: "column",
  height: "204px"
};
const childStyle = {
  display: "flex",
  alignItems: "start",
  justifyContent: "space-evenly",
  flexDirection: "column",
  height: "100px"
};
const viewPar = {
  fontFamily: "Lato",
  fontFtyle: "normal",
  fontWeight: "normal",
  fontSize: "13px",
  lineHeight: "16px",
  display: "flex",
  alignItems: "center",
  color: "#242953"
};


export default function TransitionsModal({ name, surname, open, isClose }) {
  const classes = useStyles();
  const [toRedirect, setRedirect] = React.useState(false);

  const handleClose = () => {
    isClose(false)
  };
  

  function openVacationCalendar() {
    setRedirect(true);
  };
  
  return (
    <div>
      {toRedirect ? <Redirect to="/vacations" /> : null}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div style={style}>
              <div style={childStyle}>
                <h2 id="transition-modal-title">Oops!</h2>
                <p id="transition-modal-description">
                  {name} {surname} will be on vacation on these dates!
                </p>
              </div>
              <div style={childStyle}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.root}
                  onClick={openVacationCalendar}
                >
                  View
                </Button>
                <p style={viewPar}>
                  View vacation calendar to see who is available at these dates
                </p>
              </div>
            </div>
            <img src={vacationBigImg} alt="vacation"></img>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
