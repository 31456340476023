import React from "react";
import OneProject from "./OneProject";


const ViewOneProject = ({ view, selectedProject, firstSelectProject, closeLoader }) => {
  return (
    <React.Fragment>
      <OneProject
        view={view}
        selectedProject={selectedProject}
        firstSelectProject={firstSelectProject}
        closeLoader={closeLoader}
      />
    </React.Fragment>
  );
};

export default ViewOneProject;
