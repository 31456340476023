import moment from "moment";
import { async } from "@firebase/util";
export const createProject = project => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let position;
    let project_id;
    firestore
      .collection("projects")
      .get()
      .then(snap => {
        position = snap.docs.length ;
      })
      .then(() => {
        firestore
          .collection("projects")
          .add({
            name: project.name,
            status: "In progress",
            position: position 
          })
          .then(resp => {
            project_id = resp.id;
            firestore
              .collection("projects")
              .doc(resp.id)
              .update({
                id: resp.id
              });

            project.selectedEmployees.forEach(element => {
              firestore
                .collection("projects")
                .doc(resp.id)
                .collection("employeesSubcollect")
                .doc(element.id)
                .set({
                  id: element.id,
                  firstName: element.firstName,
                  lastName: element.lastName,
                  position: element.position,
                  phone: element.phone,
                  picture: element.picture,
                  start: element.start,
                  end: element.end,
                  resourceId: resp.id,
                  title: element.title
                });
            });

            project.selectedEmployees.forEach(elem => {
              firestore
                .collection("employees")
                .get()
                .then(() => {
                  firestore
                    .collection("employees")
                    .doc(elem.id)
                    .collection("projectsSubcollect")
                    .doc(project_id)
                    .set({
                      id: project_id,
                      name: project.name,
                      status: "In progress"
                    });
                });
            });
          })

          .then(() => {
            dispatch({ type: "CREATE_PROJECT", project });
          })
          .catch(err => {
            dispatch({ type: "CREATE_PROJECT_ERROR", err });
          });
      });
  };
};

export const updateProject = (name, id, employee, status) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const collect = firestore.collection("projects");

    collect
      .doc(id)
      .get()
      .then(item => {
        return status === ""
          ? collect.doc(id).update({
              name: name,
              status: item.data().status
            })
          : collect.doc(id).update({
              name: name,
              status: status
            });
      })
      .then(() => {
        collect
          .doc(id)
          .collection("employeesSubcollect")
          .get()
          .then(() => {
            employee.forEach(item => {
              collect
                .doc(id)
                .collection("employeesSubcollect")
                .doc(item.id)
                .set({
                  id: item.id,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  position: item.position,
                  phone: item.phone,
                  picture: item.picture,
                  start: item.start,
                  end: item.end,
                  resourceId: id,
                  title: item.title
                });
            });
            employee.forEach(item => {
              firestore
                .collection("employees")
                .doc(item.id)
                .collection("projectsSubcollect")
                .doc(id)
                .set({
                  id: id,
                  name: name,
                  status: status
                });
            });
          });
      })

      .then(() => {
        dispatch({ type: "UPDATE_PROJECT_SUCCESS", id });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_PROJECT_ERROR", err });
      });
  };
};

export const addEmployeeCalendar = createProject => {
  return (dispatch, getState, { getFirestore }) => {
    let start = new Date(createProject.startDate)
    start.setHours(0)
    start.setMinutes(0)
    let end = new Date(createProject.endDate)
    end.setHours(23)
    end.setMinutes(59)
    let END = moment(end)
    let START = moment(start)
    console.log('startDate: ', createProject.startDate);
    console.log('endDate: ', createProject.endDate);
    console.log('endDate-Moment: ', moment(END).format("YYYY-MM-DD HH:mm"));
    console.log('startDate-Moment: ', moment(START).format("YYYY-MM-DD HH:mm"));

    const firestore = getFirestore();
    const collect = firestore.collection("projects");
    let item;
     collect
      .doc(createProject.resourceId)
      .collection("employeesSubcollect")
      .get()
      .then(() => {
        createProject.selectedEmployees.forEach(employee => {
          item = employee;
          collect
            .doc(createProject.resourceId)
            .collection("employeesSubcollect")
            .doc(employee.id)
            .set({
              id: employee.id,
              firstName: employee.firstName,
              lastName: employee.lastName,
              position: employee.position,
              phone: employee.phone,
              picture: employee.picture,
              start: moment(START).format("YYYY-MM-DD HH:mm"),
              end:  moment(END).format("YYYY-MM-DD HH:mm"),
              resourceId: createProject.resourceId,
              title: employee.title
            });
          firestore
            .collection("employees")
            .doc(employee.id)
            .collection("projectsSubcollect")
            .doc(createProject.resourceId)
            .set({
              id: createProject.resourceId,
              name: createProject.slotName,
              status: "In progress",
              start: moment(createProject.startDate).format("YYYY-MM-DD HH:mm"),
              end: moment(createProject.endDate).format("YYYY-MM-DD HH:mm")
            });
        });
        
      })
      .then(() => {
        dispatch({ type: "ADD_EMPLOYEE_SUCCESS", item });
      })
      .catch(err => {
        dispatch({ type: "ADD_EMPLOYEE_ERROR", err });
      });
  };
};

export const moveEmployee = (
  previousId,
  event,
  slotId,
  slotName,
  start,
  end
) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const collect = firestore.collection("projects");
    if (previousId === slotId) {
      collect
        .doc(slotId)
        .collection("employeesSubcollect")
        .doc(event.id)
        .update({
          start: start,
          end: end
        });
      firestore
        .collection("employees")
        .doc(event.id)
        .collection("projectsSubcollect")
        .doc(slotId)
        .set({
          id: slotId,
          name: slotName,
          start: start,
          end: end
        })
        .then(() => {
          dispatch({ type: "MOVE_EMPLOYEE_SUCCESS", event });
        })
        .catch(err => {
          dispatch({ type: "MOVE_EMPLOYEE_ERROR", err });
        });
    } else {
      firestore
        .collection("employees")
        .doc(event.id)
        .collection("projectsSubcollect")
        .doc(previousId)
        .delete()

        .then(() => {
          collect
            .doc(previousId)
            .collection("employeesSubcollect")
            .doc(event.id)
            .delete();
        })

        .then(() => {
          collect
            .doc(slotId)
            .collection("employeesSubcollect")
            .doc(event.id)
            .set({
              id: event.id,
              firstName: event.firstName,
              lastName: event.lastName,
              position: event.position,
              phone: event.phone,
              picture: event.picture,
              title: event.title,
              start: start,
              end: end,
              resourceId: slotId
            });
        });

      firestore
        .collection("employees")
        .doc(event.id)
        .collection("projectsSubcollect")
        .doc(slotId)
        .set({
          id: slotId,
          name: slotName,
          start: start,
          end: end
        })
        .then(() => {
          dispatch({ type: "MOVE_EMPLOYEE_SUCCESS", event });
        })
        .catch(err => {
          dispatch({ type: "MOVE_EMPLOYEE_ERROR", err });
        });
    }
  };
};

export const moveStartDate = (event, newStart) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const collect = firestore.collection("projects");

    collect
      .doc(event.resourceId)
      .collection("employeesSubcollect")
      .doc(event.id)
      .update({
        start: newStart
      });

    firestore
      .collection("employees")
      .doc(event.id)
      .collection("projectsSubcollect")
      .doc(event.resourceId)
      .get()
      .then(item => {
        firestore
          .collection("employees")
          .doc(event.id)
          .collection("projectsSubcollect")
          .doc(event.resourceId)
          .set({
            start: newStart,
            end: event.end,
            id: event.resourceId,
            name: item.data().name,
            status: item.data().status
          });
      })

      .then(() => {
        dispatch({ type: "START_DATE_SUCCESS", event });
      })
      .catch(err => {
        dispatch({ type: "START_DATE_ERROR", err });
      });
  };
};

export const moveEndDate = (event, newEnd) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const collect = firestore.collection("projects");

    collect
      .doc(event.resourceId)
      .collection("employeesSubcollect")
      .doc(event.id)
      .update({
        end: newEnd
      });

    firestore
      .collection("employees")
      .doc(event.id)
      .collection("projectsSubcollect")
      .doc(event.resourceId)
      .get()
      .then(item => {
        firestore
          .collection("employees")
          .doc(event.id)
          .collection("projectsSubcollect")
          .doc(event.resourceId)
          .set({
            start: event.start,
            end: newEnd,
            id: event.resourceId,
            name: item.data().name,
            status: item.data().status
          });
      })

      .then(() => {
        dispatch({ type: "END_DATE_SUCCESS", event });
      })
      .catch(err => {
        dispatch({ type: "END_DATE_ERROR", err });
      });
  };
};

export const deleteSelectProject = selectedProject => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let element;
    selectedProject.forEach(value => {
      firestore
        .collection("projects")
        .doc(value)
        .collection("employeesSubcollect")
        .get()
        .then(snap => {
          snap.forEach(elem => {
            element = elem;
            firestore
              .collection("projects")
              .doc(value)
              .collection("employeesSubcollect")
              .doc(elem.id)
              .delete();
          });
        });
      firestore
        .collection("projects")
        .doc(value)
        .delete();

      firestore
        .collection("employees")
        .get()
        .then(snap => {
          snap.forEach(elem => {
            firestore
              .collection("employees")
              .doc(elem.id)
              .collection("projectsSubcollect")
              .doc(value)
              .delete();
          });
        })
        .then(() => {
          dispatch({ type: "DELETE_PROJECT_SUCCESS", element });
        })
        .catch(err => {
          dispatch({ type: "DELETE_PROJECT_ERROR", err });
        });
    });
  };
};

export const deleteProject = id => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let collect = firestore.collection("projects").doc(id);

    collect
      .collection("employeesSubcollect")
      .get()
      .then(snap => {
        snap.forEach(elem => {
          collect
            .collection("employeesSubcollect")
            .doc(elem.id)
            .delete();
        });
      });
    collect.delete();

    firestore
      .collection("employees")
      .get()
      .then(snap => {
        snap.forEach(elem => {
          firestore
            .collection("employees")
            .doc(elem.id)
            .collection("projectsSubcollect")
            .doc(id)
            .delete();
        });
      })

      .then(() => {
        dispatch({ type: "DELETE_PROJECT_SUCCESS", id });
      })
      .catch(err => {
        dispatch({ type: "DELETE_PROJECT_ERROR", err });
      });
  };
};

export const deleteEmployeeInProject = (projectid, employeeId) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("projects")
      .doc(projectid)
      .collection("employeesSubcollect")
      .doc(employeeId)
      .delete();

    firestore
      .collection("employees")
      .doc(employeeId)
      .collection("projectsSubcollect")
      .doc(projectid)
      .delete()

      .then(() => {
        dispatch({ type: "DELETE_EMPLOYEEINPROJECT_SUCCESS", employeeId });
      })
      .catch(err => {
        dispatch({ type: "DELETE_EMPLOYEEINPROJECT_ERROR", err });
      });
  };
};

export const dropOneProject = (selectedProject, id, startDrop, endDrop) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const collect = firestore.collection("projects");

    collect
      .doc(selectedProject.id)
      .collection("employeesSubcollect")
      .doc(id)
      .update({
        start: startDrop,
        end: endDrop
      });

    firestore
      .collection("employees")
      .doc(id)
      .collection("projectsSubcollect")
      .doc(selectedProject.id)
      .update({
        start: startDrop,
        end: endDrop
      })
      .then(() => {
        dispatch({ type: "DROP_VACATION_SUCCESS", id });
      })
      .catch(err => {
        dispatch({ type: "DROP_VACATION_ERROR", err });
      });
  };
};

export const addEmployeeOneProject = (selectedProject, createProject) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const collect = firestore.collection("projects");


    let start = new Date(createProject.startDate)
    start.setHours(0)
    start.setMinutes(0)
    let end = new Date(createProject.endDate)
    end.setHours(23)
    end.setMinutes(59)
    let END = moment(end)
    let START = moment(start)
    console.log('startDate: ', createProject.startDate);
    console.log('endDate: ', createProject.endDate);
    console.log('endDate-Moment: ', moment(END).format("YYYY-MM-DD HH:mm"));
    console.log('startDate-Moment: ', moment(START).format("YYYY-MM-DD HH:mm"));



    let item;
    collect
      .doc(selectedProject.id)
      .collection("employeesSubcollect")
      .get()
      .then(() => {
        createProject.selectedEmployees.forEach(employee => {
          item = employee;
          collect
            .doc(selectedProject.id)
            .collection("employeesSubcollect")
            .doc(employee.id)
            .set({
              id: employee.id,
              firstName: employee.firstName,
              lastName: employee.lastName,
              position: employee.position,
              phone: employee.phone,
              picture: employee.picture,
              start: moment(START).format("YYYY-MM-DD HH:mm"),
              end:  moment(END).format("YYYY-MM-DD HH:mm"),
              resourceId: selectedProject.id,
              title: employee.title
            });

          firestore
            .collection("employees")
            .doc(employee.id)
            .collection("projectsSubcollect")
            .doc(selectedProject.id)
            .set({
              id: selectedProject.id,
              name: selectedProject.name,
              status: selectedProject.status,
              start:  moment(START).format("YYYY-MM-DD HH:mm"),
              end: moment(END).format("YYYY-MM-DD HH:mm")
            });
        });
      })

      .then(() => {
        dispatch({ type: "ADD_EMPLOYEE_ONEPROJECT_SUCCESS", item });
      })
      .catch(err => {
        dispatch({ type: "ADD_EMPLOYEE_ONEPROJECT_ERROR", err });
      });
  };
};

export const editEmployeeCalendar = editEmployee => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const collect = firestore.collection("projects");
    collect
      .doc(editEmployee.resourceId)
      .collection("employeesSubcollect")
      .doc(editEmployee.selectedEmployees.id)
      .update({
        start: editEmployee.startDate,
        end: editEmployee.endDate
      })
      .then(() => {
        dispatch({ type: "EDIT_EMPLOYEE_SUCCESS", editEmployee });
      })
      .catch(err => {
        dispatch({ type: "EDIT_EMPLOYEE_ERROR", err });
      });
  };
};

export const editEmployeeOneCalendar = editEmployee => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const collect = firestore.collection("projects");
    collect
      .doc(editEmployee.resourceId)
      .collection("employeesSubcollect")
      .doc(editEmployee.selectedEmployees)
      .update({
        start: editEmployee.startDate,
        end: editEmployee.endDate
      })
      .then(() => {
        dispatch({ type: "EDIT_EMPLOYEE_SUCCESS", editEmployee });
      })
      .catch(err => {
        dispatch({ type: "EDIT_EMPLOYEE_ERROR", err });
      });
  };
};
