import React, { useState, useEffect } from "react";
import classNames from "classnames";
// import { useFirebase } from "react-redux-firebase";

import menu from "../assets/employees/menu.svg";
import EditEmployee from "./Popup/EditEmployee";
import uploadPhoto from "../assets/employees/upload_photo.svg";

const Employee = (
  {
    employee,
    projects,
    handleDelete,
    pictureUrl,
    handleChange,
    handlePicture,
    handleUpdate,
    position,
    getPositionItem,
    getProjectItem,
    selectedProject
  } = this.props
) => {
  // const firebase = useFirebase();
  const [isToggled, setisToggled] = useState(false);
  const [isEddit, setisEdit] = useState(false);
  // const [name, setName] = useState([]);

  function toggleMore() {
    setisToggled(!isToggled);
  }

  function toggleEdit() {
    setisEdit(!isEddit);
  }

  // function getProjectname() {
  //   let projectsArr = [];
  //   const Firebase = firebase.firestore();
  //   Firebase.collection("employees")
  //     .doc(employee.id)
  //     .collection("projectsSubcollect")
  //     .get()
  //     .then(snap => {
  //       snap.forEach(item => {
  //         projectsArr.push(item.data().name);
  //       });
  //       setName(projectsArr);
  //     });
  // }

  useEffect(() => {
    // getProjectname();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div key={employee.id} className="employee-box">
        <div className="employee-block">
          <img
            onClick={toggleMore}
            src={menu}
            alt="menu"
            className={classNames("show-more", isToggled ? "active" : "")}
          />
          <picture className="employee-block-photo">
            <img
              src={employee.picture === null ? uploadPhoto : employee.picture}
              alt={employee.lastName}
              className="employee-img"
            />
            <p className="employee-name">{`${employee.firstName} ${employee.lastName}`}</p>
          </picture>
          <div className="employee-block-info">
            <p className="employee-text">
              Position: <span>{employee.position}</span>
            </p>
            {/* <p className="employee-text project-size project-size-current">
              Project: <span>{name.length === 0 ? "none" : name}</span>
            </p> */}
          </div>
        </div>
        {isToggled ? (
          <div className="employee-edit">
            <div className="employee-edit-button">
              <button
                onClick={() => toggleEdit(employee.id)}
                className="employee-text"
              >
                Edit
              </button>
              {employee.isAdmin === "admin" ? null : (
                <button
                  className="employee-text"
                  onClick={e => handleDelete(e, employee.id)}
                >
                  Delete
                </button>
              )}
            </div>
            <div className="employee-edit-info">
              <p className="employee-creds">Email for login:</p>
              <p className="employee-text">{employee.email}</p>
              <p className="employee-creds employee-password">
                Password for login:
              </p>
              <p className="employee-text">{employee.password}</p>
              <p className="employee-text employee-phone">Phone number:</p>
              <p className="employee-text">{employee.phone}</p>
            </div>
          </div>
        ) : null}
      </div>
      {isEddit && (
        <EditEmployee
          toggleEdit={toggleEdit}
          isEddit={isEddit}
          employee={employee}
          projects={projects}
          pictureUrl={pictureUrl}
          handleChange={handleChange}
          handlePicture={handlePicture}
          handleUpdate={handleUpdate}
          position={position}
          getPositionItem={getPositionItem}
          getProjectItem={getProjectItem}
          selectedProject={selectedProject}
        />
      )}
    </React.Fragment>
  );
};

export default Employee;
