import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { storage } from "../firebase";
import addEmployee from "../assets/employees/add_employee.svg";
import AddEmployeePopup from "../components/Popup/AddEmployee";

import Employee from "../components/Employee";
import iconSearch from "../assets/employees/search.svg";
import {
  createEmployee,
  deleteEmployee,
  updateEmployee
} from "../store/action/employeeActions";

import Resizer from "react-image-file-resizer";

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopupEmployee: false,
      filterValue: "",
      firstName: "",
      lastName: "",
      phone: "",
      position: "",
      project: "",
      picture: null,
      pictureUrl: null,
      email: "",
      password: "",
      isEddit: false,
      setisEdit: false,
      selectedProject: [],
      createAuth: false
    };
  }

  togglePopup = () => {
    this.setState({
      showPopupEmployee: !this.state.showPopupEmployee,
      pictureUrl: null
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  getProjectItem = someValue => {
    this.setState({
      selectedProject: someValue
    });
  };

  getPositionItem = position => {
    this.setState({
      position: position
    });
  };

  handlePicture = async e => {
    let reader = new FileReader();
    let file = e.target.files[0];

    Resizer.imageFileResizer(
      e.target.files[0],
      300,
      300,
      "JPEG",
      100,
      0,
      uri => {
        console.log(uri);
        setTimeout(() => {
          this.setState({ pictureUrl: uri });
        });
      }
    );

    let storRef = storage.ref(file.name);
    storRef.put(file);
    
    reader.onloadend = () => {
      this.setState({
        picture: file.name
      });
    };
    reader.readAsDataURL(file);
  };

  handleDelete = (e, id) => {
    e.preventDefault();
    this.props.deleteEmployee(id);
  };

  handleUpdate = (e, id, position) => {
    e.preventDefault();
    let firstName = e.target.firstName.value;
    let lastName = e.target.lastName.value;
    let phone = e.target.phone.value;
    let project = this.state.selectedProject.name
      ? this.state.selectedProject.name
      : null;

    let pictureUrl;
    if (this.state.pictureUrl === null) {
      pictureUrl = e.target.children[1].children[0].src;
    } else {
      pictureUrl = this.state.pictureUrl;
    }

    let positionEmployee;
    if (this.state.position === "") {
      positionEmployee = position;
    } else {
      positionEmployee = this.state.position;
    }

    this.props.updateEmployee(
      firstName,
      lastName,
      phone,
      positionEmployee,
      project,
      pictureUrl,
      id
    );
    this.setState({ pictureUrl: null, picture: null, position: "" });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.createEmployee(this.state);
    this.setState({ showPopupEmployee: !this.state.showPopupEmployee });
  };

  render() {
    const {
      showPopupEmployee,
      filterValue,
      selectedProject,
      position,
      email,
      firstName,
      password,
      lastName
    } = this.state;
    const { employees, projects } = this.props;

    const enabled =
      email.length > 0 &&
      password.length > 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      position.length > 0;

    return (
      <React.Fragment>
        <header className="cabinet-header">
          <div className="cabinet-header-block header-employees">
            <p className="header-title">Employees</p>
            <span className="custom-input__group search-input">
              <input
                type="text"
                className="custom-input"
                onChange={e => this.setState({ filterValue: e.target.value })}
                value={filterValue || ""}
                placeholder="Search..."
              />
              <img src={iconSearch} alt="search" />
            </span>
          </div>
        </header>
        <div className="cabinet-content">
          <section className="employees-content">
            <div className="employee-box">
              <div className="employee-add">
                <picture className="employee-add-img">
                  <img
                    src={addEmployee}
                    alt="addEmployee"
                    className="employee-img"
                  />
                </picture>
                <button onClick={this.togglePopup} className="add-button">
                  Add new employee
                </button>
              </div>
            </div>

            {employees &&
              employees
                .filter(
                  employee =>
                    employee.lastName
                      .toLowerCase()
                      .includes(filterValue.toLowerCase()) ||
                    employee.firstName
                      .toLowerCase()
                      .includes(filterValue.toLowerCase()) ||
                    employee.position
                      .toLowerCase()
                      .includes(filterValue.toLowerCase()) ||
                    employee.project
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())
                )
                .map(employee => (
                  <Employee
                    employee={employee}
                    handleDelete={this.handleDelete}
                    key={employee.id}
                    projects={projects}
                    pictureUrl={this.state.pictureUrl}
                    handleChange={this.handleChange}
                    handleUpdate={this.handleUpdate}
                    handlePicture={this.handlePicture}
                    getPositionItem={this.getPositionItem}
                    position={position}
                    getProjectItem={this.getProjectItem}
                    selectedProject={selectedProject}
                  />
                ))}
          </section>
        </div>
        {showPopupEmployee && (
          <AddEmployeePopup
            employeePopup={this.togglePopup}
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            handlePicture={this.handlePicture}
            projects={projects}
            picture={this.state.picture}
            pictureUrl={this.state.pictureUrl}
            getProjectItem={this.getProjectItem}
            selectedProject={selectedProject}
            getPositionItem={this.getPositionItem}
            position={position}
            enabled={enabled}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    employees: state.firestore.ordered.employees,
    projects: state.firestore.ordered.projects
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createEmployee: newUser => dispatch(createEmployee(newUser)),
    deleteEmployee: id => dispatch(deleteEmployee(id)),
    updateEmployee: (
      firstName,
      lastName,
      phone,
      position,
      project,
      pictureUrl,
      id
    ) =>
      dispatch(
        updateEmployee(
          firstName,
          lastName,
          phone,
          position,
          project,
          pictureUrl,
          id
        )
      )
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "employees" }, { collection: "projects" }])
)(Employees);
