import React from "react";
import addWorker from "../../assets/employees/upload_photo.svg";
import remove from "../../assets/remove.svg";

// const PreviewListEployees = props => {
//   const { employees, selectedEmployees, handleChange } = props;

class PreviewListEployees extends React.Component {
  render() {
    const {
      selectedEmployees,
      handleChange,
      listArr,
      removeEmployee
    } = this.props;

    return (
      <div
        className="custom-input custom-input--project line-bottom"
        multiple={true}
        value={selectedEmployees}
        onChange={handleChange}
        required
      >
        {listArr &&
          listArr.map(employee => (
            <div className="preview-list-employee" key={employee.id}>
              <div
                className="employee-in-edit-project"
                value={`${employee.firstName} ${employee.lastName}`}
                onChange={handleChange}
              >
                <picture className="project-picture">
                  <img
                    src={
                      employee.picture === null ? addWorker : employee.picture
                    }
                    alt="employee-icon"
                    className="edit-project-employee-icon"
                  />
                </picture>
                {`${employee.firstName} ${employee.lastName}`}
              </div>
              <img
                src={remove}
                alt="remove"
                className="remove-button"
                onClick={() => removeEmployee(employee.id)}
              />
            </div>
          ))}
      </div>
    );
  }
}

export default PreviewListEployees;
