import React, { useState, useEffect } from "react";
import { useFirebase } from "react-redux-firebase";
import classNames from "classnames";
import EditProject from "./Popup/EditProject";
import edit from "../assets/projects/edit_project.svg";
import iconDeleteProject from "../assets/projects/delete.svg";

const Project = (
  {
    employees,
    project,
    getUserId,
    selectedEmployees,
    listArr,
    addListArr,
    handleDelete,
    handleChange,
    handleUpdate,
    deleteEmployeeInProject,
    removeEmployee,
    checked,
    selectedProject,
    handleSelectedProject,
    changeStatus
  } = this.props
) => {
  const [isEddit, setisEdit] = useState(false);
  const [numb, setNumb] = useState([]);
  const [employeeInfo, setEmployee] = useState([]);

  const firebase = useFirebase();

  function toggleEdit() {
    setisEdit(!isEddit);
  }

  function getNumber() {
    const Firebase = firebase.firestore();
    Firebase.collection("projects")
      .doc(project.id)
      .collection("employeesSubcollect")
      .get()
      .then(snap => {
        setNumb(snap.docs.length);
      });
  }

  function getProjetEmployee() {
    let employeesArr = [];
    const Firebase = firebase.firestore();
    Firebase.collection("projects")
      .doc(project.id)
      .collection("employeesSubcollect")
      .get()
      .then(snap => {
        snap.forEach(item => {
          employeesArr.push(item.data());
        });
        setEmployee(employeesArr);
      });
  }

  function handleDeleteEmployeeProject(projctId, employeeId) {
    const list = Array.from(employeeInfo);
    list.splice(employeeId, 1);
    setEmployee(list);
    deleteEmployeeInProject(projctId, employeeId);
  }

  useEffect(() => {
    getNumber();
    getProjetEmployee();
  }, [getNumber, getProjetEmployee]);

  return (
    <React.Fragment>
      <div className="custom-element">
        <div className="custom-table__item custom-checkbox">
          <input
            type="checkbox"
            key={project.id}
            name={project.id}
            value={project.id}
            checked={
              checked || (selectedProject && selectedProject.has(project.id))
            }
            onChange={e => {
              handleSelectedProject(false, true, project.id, e.target.checked);
            }}
          />
        </div>
        <div className="custom-table__item">
          <p className="project-size">{project.name}</p>
        </div>
        <div className="custom-table__item">{numb}</div>
        <div
          className={classNames(
            "custom-table__item",
            project.status === "Finished" ? "finished" : "custom-table__item"
          )}
        >
          <p>{project.status}</p>
        </div>
        <div className="custom-table__item">
          {selectedProject.size < 2 ? (
            <div className="custom-edit">
              <div className="edit-project">
                <img
                  src={edit}
                  alt="edit"
                  onClick={() => toggleEdit(project.id)}
                />
              </div>
              <div className="line"></div>
              <div>
                <img
                  src={iconDeleteProject}
                  alt="delete"
                  onClick={e => handleDelete(e, project.id)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {isEddit && (
        <EditProject
          toggleEdit={toggleEdit}
          isEddit={isEddit}
          employees={employees}
          project={project}
          getUserId={getUserId}
          listArr={listArr}
          addListArr={addListArr}
          selectedEmployees={selectedEmployees}
          handleChange={handleChange}
          handleUpdate={handleUpdate}
          employeeInfo={employeeInfo}
          removeEmployee={removeEmployee}
          handleDeleteEmployeeProject={handleDeleteEmployeeProject}
          changeStatus={changeStatus}
        />
      )}
    </React.Fragment>
  );
};

export default Project;
