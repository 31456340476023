import React from "react";
import { NavLink } from "react-router-dom";
import ReactSVG from "react-svg";
// Icons
import iconHome from "../assets/icons/home.svg";
import iconProjects from "../assets/icons/projects.svg";
import iconEmployees from "../assets/icons/employees.svg";
import iconVacations from "../assets/icons/vacationMenu.svg";
import iconLogout from "../assets/icons/logout.svg";

const Navigation = ({ signOut } = this.props) => {
  return (
    <nav className="sidebar-nav">
      <NavLink to="/home" className="sidebar-link">
        <ReactSVG
          src={iconHome}
          className="svg-wrapper"
          svgclassname="svg-icon"
        />
        Home
      </NavLink>
      <NavLink to="/projects" className="sidebar-link">
        <ReactSVG
          src={iconProjects}
          className="svg-wrapper"
          svgclassname="svg-icon"
        />
        Projects
      </NavLink>
      <NavLink to="/employees" className="sidebar-link">
        <ReactSVG
          src={iconEmployees}
          className="svg-wrapper"
          svgclassname="svg-icon"
        />
        Employees
      </NavLink>
      <NavLink to="/vacations" className="sidebar-link">
        <ReactSVG
          src={iconVacations}
          className="svg-wrapper"
          svgclassname="svg-icon"
        />
        Vacations
      </NavLink>

      <button
        onClick={signOut}
        className="sidebar-link sidebar-link__logout"
      >
        <ReactSVG
          src={iconLogout}
          className="svg-wrapper"
          svgclassname="svg-icon svg-icon__logout"
        />
        Logout
      </button>
    </nav>
  );
};

export default Navigation;
