const initState = {
  authError: null
};

const employeeReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_EMPLOYEE":
      console.log("create employee");
      return { ...state, authError: null };
    case "CREATE_EMPLOYEE_ERROR":
      console.log("create employee error");
      return { ...state, authError: action.err.message };

    case "UPDATE_EMPLOYEE_SUCCESS":
      console.log("update", action.id);
      return state;
    case "UPDATE_EMPLOYEE_ERROR":
      console.log("update employee error", action.err);
      return state;

    case "DELETE_EMPLOYEE_SUCCESS":
      console.log("deleted", action.id);
      return state;
    case "DELETE_EMPLOYEE_ERROR":
      console.log("delete employee error", action.err);
      return state;

    case "ADD_VACATION_SUCCESS":
      console.log("add employee vacation", action.vacation);
      return state;
    case "ADD_VACATION_ERROR":
      console.log("add employee vacation error", action.err);
      return state;

      case "EDIT_VACATION_SUCCESS":
        console.log("edit employee vacation", action.vacation);
        return state;
      case "EDIT_VACATION_ERROR":
        console.log("edit employee vacation error", action.err);
        return state;

      case "DELETE_VACATION_SUCCESS":
        console.log("DELETE employee vacation", action.id);
        return state;
      case "DELETE_VACATION_ERROR":
        console.log("DELETE employee vacation error", action.err);
        return state;

    case "DROP_VACATION_SUCCESS":
      console.log("DROP employee vacation", action.id);
      return state;
    case "DROP_VACATION_ERROR":
      console.log("DROP employee vacation error", action.err);
      return state;

    default:
      return state;
  }
};

export default employeeReducer;
