import authReducer from './authReducer'
import projectReducer from './projectReducer'
import employeeReducer from './employeeReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  projects: projectReducer,
  employees: employeeReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer