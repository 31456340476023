import React from "react";
import remove from "../../assets/remove.svg";

class ListEmployees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: ""
    };
  }

  render() {
    const { employees, toggleProject, getUserId } = this.props;
    const { filterValue } = this.state;
    return (
      <div className="list-employees">
        <img
          src={remove}
          alt="remove"
          className="popup-close popup-close-employee"
          onClick={toggleProject}
        />
        <div className="list-content">
          <p className="list-content-title">Employees</p>

          <span className="custom-input__group search-input">
            <input
              type="text"
              className="custom-input"
              onChange={e => this.setState({ filterValue: e.target.value })}
              value={filterValue || ""}
              placeholder="Search..."
            />
          </span>
          <div className="list-content-employees">
            {employees &&
              employees
                .filter(
                  employee =>
                    employee.lastName
                      .toLowerCase()
                      .includes(filterValue.toLowerCase()) ||
                    employee.firstName
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())
                )
                .map(employee => (
                  <div
                    key={employee.id}
                    className="employees-name"
                    onClick={() => {
                      getUserId(employee);
                      toggleProject();
                    }}
                    value={employee.id}
                  >{`${employee.firstName} ${employee.lastName}`}</div>
                ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ListEmployees;
