import React from "react";
import './loader.css';

export default function Loader() {
  return (
    <div className='mainLoader'>
      <div className="loadingio-spinner-spinner-o6k82occy3r">
      <div className="ldio-l5794e4nb5">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    </div>
  );
}
