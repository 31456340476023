import React, { useState, useRef, useEffect } from "react";
import closePopup from "../../assets/employees/closePopup.svg";
import uploadPhoto from "../../assets/employees/upload_photo.svg";
import PreviewPicture from "./PreviewPicture";
import ListPosition from "./ListPosition";

export const AddEmployeePopup = (
  {
    employeePopup,
    handleSubmit,
    handleChange,
    handlePicture,
    pictureUrl,
    getPositionItem,
    position,
    enabled
  } = this.props
) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangePopup = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div className="popup">
      <div className="popup_inner employee-popup">
        <img
          onClick={employeePopup}
          src={closePopup}
          alt="close"
          className="button-close-popup"
        />
        <form onSubmit={handleSubmit} className="employee-content">
          <div className="employee-content-form">
            <h1 className="popup-title">Add new employee</h1>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--employee"
                type="text"
                name="firstname"
                placeholder="Firstname"
                id="firstName"
                onChange={handleChange}
                minLength={1}
                maxLength={20}
                required
              />
            </div>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--employee"
                type="text"
                name="surname"
                placeholder="Surname"
                id="lastName"
                maxLength={20}
                onChange={handleChange}
                required
              />
            </div>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--employee"
                type="number"
                name="phone"
                placeholder="Phone number"
                id="phone"
                maxLength={20}
                onChange={e => handleChange(e)}
                required
              />
            </div>
            <div className="custom-input__group" ref={node}>
              <div
                className="custom-input custom-input--employee"
                onClick={e => setOpen(!open)}
              >
                <p
                  id="custom-label"
                  className="project-size project-size-select"
                >
                  {position === "" ? "Position" : position}
                </p>
                <div
                  className={open ? "arrow-down arrow-rotate" : "arrow-down"}
                ></div>
              </div>
              {open && (
                <ListPosition
                  getPositionItem={getPositionItem}
                  togglePosition={handleChangePopup}
                />
              )}
            </div>
          </div>
          <div className="employee-content-photo">
            {pictureUrl === null ? (
              <picture className="upload-photo">
                <img src={uploadPhoto} alt="addEmployee" />
              </picture>
            ) : (
              <PreviewPicture pictureUrl={pictureUrl} />
            )}
            <label className="custom-file-upload">
              <input type="file" onChange={handlePicture} id="image" />
              <p className="upload-button">Upload photo</p>
            </label>
            <p className="upload-text">Upload a profile picture (JPG or PNG)</p>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--creds"
                type="email"
                name="email"
                placeholder="Email for login"
                id="email"
                maxLength={50}
                onChange={handleChange}
                required
              />
            </div>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--creds"
                type="test"
                name="password"
                placeholder="Password for login"
                id="password"
                minLength={6}
                maxLength={50}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <button
            className="add-button"
            disabled={!enabled}
          >
            Add new employee
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEmployeePopup;
