export const signIn = credentials => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let user_id;
    let create_uid;
    const collect = firestore.collection("employees");

    collect.get().then(async snap => {
      snap.forEach(async employee => {
        if (
          employee.data().email === credentials.email &&
          employee.data().password === credentials.password &&
          employee.data().createAuth === false
        ) {
          user_id = employee.data().id;
          sessionStorage.setItem("email", credentials.email);

          //   console.log('user_id: ', user_id);
          //   sessionStorage.setItem('id', user_id)

          //  await collect
          //     .doc(user_id)
          //     .collection("projectsSubcollect")
          //     .get()
          //     .then(snap => {
          //       console.log('asdasddas');
          //       snap.forEach(elem => {
          //         collect
          //           .doc(user_id)
          //           .collection("projectsSubcollect")
          //           .doc(elem.id)
          //           .delete();
          //       });
          //     });
          //     console.log('user_idHERE');
         await firebase
            .auth()
            .createUserWithEmailAndPassword(
              credentials.email,
              credentials.password
            )
            .then(resp => {
              create_uid = resp.user.uid;
              console.log(user_id);

              collect.doc(user_id).update({
                // firstName: employee.data().firstName,
                // lastName: employee.data().lastName,
                // position: employee.data().position,
                // phone: employee.data().phone,
                // project: employee.data().project,
                // picture: employee.data().picture,
                // email: employee.data().email,
                // password: employee.data().password,
                // start: employee.data().start,
                // end: employee.data().end,
                // resourceId: employee.data().resourceId,
                // title: employee.data().title,
                uid: resp.user.uid,
                createAuth: true
              });

              // firestore
              //   .collection("projects")
              //   .get()
              //   .then(snap => {
              //     console.log('user_idHdsfsfsdfERE');
              //     snap.forEach(elem => {
              //       firestore
              //         .collection("projects")
              //         .doc(elem.data().id)
              //         .collection("employeesSubcollect")
              //         .doc(user_id)
              //         .delete();
              //     });
              //   });

              // firestore
              //   .collection("projects")
              //   .get()
              //   .then(snap => {
              //     console.log('user_idHEREaaaaaaaaa22');
              //     snap.forEach(elem => {
              //       firestore
              //         .collection("projects")
              //         .doc(elem.data().id)
              //         .collection("employeesSubcollect")
              //         .doc(user_id)
              //         .update({
              //           id: create_uid
              //         });
              //     });
              //   });

              //   collect
              //     .doc(user_id)
              //     .collection("projectsSubcollect")
              //     .get()
              //     .then(snap => {
              //       snap.forEach(elem => {
              //         collect
              //           .collection("projectsSubcollect")
              //           .doc(elem.id)
              //           .delete();
              //       });
              //     });

              //   collect.doc(user_id).delete();
            })

            .then(() => {
              dispatch({ type: "LOGIN_SUCCESS" });
            })
            .catch(err => {
              dispatch({ type: "LOGIN_ERROR", err });
            });
        } else {
          firebase
            .auth()
            .signInWithEmailAndPassword(credentials.email, credentials.password)
            .then(() => {
              sessionStorage.setItem("email", credentials.email);
              dispatch({ type: "LOGIN_SUCCESS" });
            })
            .catch(err => {
              dispatch({ type: "LOGIN_ERROR", err });
            });
        }
      });
    });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("id");
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};
