import React, { useState, useRef, useEffect } from "react";

import closePopup from "../../assets/employees/closePopup.svg";
import addWorker from "../../assets/projects/add_worker.svg";
import ListEmployees from "./ListEmployees";
import PreviewListEployees from "./PreviewListEmployes";

export const AddProjectPopup = (
  {
    employees,
    selectedEmployees,
    removeEmployee,
    listArr,
    addListArr,
    handleChange,
    getUserId,
    projectPopup,
    handleSubmit,
    name
  } = this.props
) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangePopup = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div className="popup">
      <div className="popup_inner project-popup">
        <img
          onClick={projectPopup}
          src={closePopup}
          alt="close"
          className="button-close-popup"
        />
        <div>
          <div className="project-popup-form">
            <form onSubmit={handleSubmit}>
              <h1 className="popup-title">Add new project</h1>
              <div className="custom-input__group">
                <input
                  className="custom-input custom-input--project"
                  type="text"
                  name="project"
                  placeholder="NewProject"
                  id="name"
                  minLength={1}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="label-workers">
                <p id="custom-label">Workers</p>
              </div>
              <div className="add-list" ref={node}>
                {!open ? (
                  <div>
                    <div className="add-worker" onClick={e => setOpen(!open)}>
                      <picture className="project-picture">
                        <img src={addWorker} alt="add" />
                      </picture>
                      <p>Add worker</p>
                    </div>
                    {selectedEmployees.length === 0 ? null : (
                      <div className="scroll-preview">
                        <PreviewListEployees
                          employees={employees}
                          selectedEmployees={selectedEmployees}
                          listArr={listArr}
                          handleChange={handleChange}
                          removeEmployee={removeEmployee}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <ListEmployees
                    employees={employees}
                    selectedEmployees={selectedEmployees}
                    addListArr={addListArr}
                    toggleProject={handleChangePopup}
                    getUserId={getUserId}
                  />
                )}
              </div>
              <button className="add-button"  disabled={name === ""}>Add new project</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProjectPopup;
