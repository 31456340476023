import React, { useState, useRef, useEffect } from "react";
import arrowProject from "../../assets/icons/arrowProject.svg";

export const AllProjectsList = (
  { projects, getProjectItem, selectedProject } = this.props
) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangePopup = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div
      id="period"
      ref={node}
      onClick={e => setOpen(!open)}
      className={open === true ? "expand" : ""}
    >
      <p>{selectedProject.name}</p>
      <img src={arrowProject} alt="arrow" />
      <div className="options">
        {projects &&
          projects.map(project => (
            <p key={project.id}>
              <span
                onClick={() => {
                  console.log('work')    
                  getProjectItem(project);
                  handleChangePopup();
                }}
                value={project.id}
              >
                {project.name}
              </span>
            </p>
          ))}
      </div>
    </div>
  );
};

export default AllProjectsList;
