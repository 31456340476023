import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import moment from "moment";
import { Col, Row } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import CreateVacation from "../components/Popup/CreateVacation";
import EditVacation from "../components/Popup/EditVacation";
import {
  addEmployeeVacation,
  dropVacation,
  deleteVacation,
  editEmployeeVacation
} from "../store/action/employeeActions";
import iconDeleteVacation from "../assets/icons/deleteVacation.svg";

class Home extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      editVacation: false,
      show: false,
      showEdit: false,
      startDate: "",
      endDate: "",
      selectedEmployees: [],
      listArr: [],
      filterValue: "",
      selectDate: '',
    };
  }

  render() {
    const { employees } = this.props;
    const {
      editVacation,
      show,
      startDate,
      endDate,
      filterValue,
      selectedEmployees,
      listArr,
      showEdit
    } = this.state;

    return (
      <React.Fragment>
        <header className="cabinet-header">
          <div className="cabinet-header-block">
            <div>
              <p className="header-title">Vacations</p>
            </div>
            <div className="header-panel">
              <button
                onClick={() => this.setState({ editVacation: !editVacation })}
                className="add-button add-button-project"
              >
                {editVacation === false ? "Add/Edit vacation" : "Apply"}
              </button>
            </div>
          </div>
        </header>
        <div className="cabinet-content">
          <section className="vacation-content">
            {editVacation === false ? (
              <Row className="default-row">
                <Col lg={9} sm={9} md={9}>
                  <FullCalendar
                    defaultView="dayGridMonth"
                    header={{
                      left: "",
                      center: "prev, title, next",
                      right: ""
                    }}
                    firstDay={1}
                    eventLimit={true}
                    plugins={[dayGridPlugin]}
                    events={employees}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="edit-row">
                <Col lg={9} sm={9} md={9}>
                  <FullCalendar
                    defaultView="dayGridMonth"
                    theme={true}
                    header={{
                      left: "",
                      center: "prev, title, next",
                      right: ""
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    firstDay={1}
                    rerenderDelay={10}
                    editable={true}
                    selectable={true}
                    droppable={true}
                    overlap={true}
                    create={true}
                    duration={true}
                    eventLimit={true}
                    forceEventDuration={true}
                    ref={this.calendarComponentRef}
                    events={employees}
                    eventDrop={this.eventDrop}
                    eventRender={this.eventRender}
                    drop={this.drop}
                    resize={true}
                    datesRender={this.datesRender}
                    eventReceive={this.eventReceive}
                    eventResize={this.eventResize}
                    eventClick={this.eventClick}
                    dateClick={this.handleDateClick}
                  />
                </Col>
              </Row>
            )}
          </section>
        </div>
        <div className="content-show">
          {show && (
            <CreateVacation
              employees={employees}
              selectedEmployees={selectedEmployees}
              listArr={listArr}
              filterValue={filterValue}
              handleFilterValue={this.handleFilterValue}
              startDate={startDate}
              endDate={endDate}
              handleSelect={this.handleSelect}
              closeCreatePopup={this.closeCreatePopup}
              handleSubmit={this.handleSubmit}
              getUser={this.getUser}
              addListArr={this.addListArr}
              removeEmployee={this.removeEmployee}
            />
          )}
          {showEdit && (
            <EditVacation
              selectedEmployees={selectedEmployees}
              closeCreatePopup={this.closeCreatePopup}
              handleEdit={this.handleEdit}
              startDate={startDate}
              endDate={endDate}
              handleSelect={this.handleSelect}
              selectDate={this.state.selectDate}
            />
          )}
        </div>
      </React.Fragment>
    );
  }

  eventClick = info => {
    this.setState({
      showEdit: true,
      selectedEmployees: info.event.id,
      selectDate: info.event
    });
  };

  eventDrop = info => {
    let startDrop = moment(info.event.start).format("YYYY-MM-DD HH:mm");
    let endDrop = moment(info.event.end).format("YYYY-MM-DD HH:mm");
    this.props.dropVacation(info.event.id, startDrop, endDrop);
  };

  eventReceive = info => {
    console.log("eventReceive");
    this.setState({
      eventReceiveStartDate: moment(info.event.start).format("YYYY-MM-DD")
    });
  };

  eventResize = info => {
    console.log(info);
  };

  eventRender = info => {
    let button = document.createElement("img");
    button.className = "remove-btn";
    button.src = iconDeleteVacation;
    button.alt = "delete";
    info.el.getElementsByClassName("fc-content")[0].append(button);
    const buttonClick = info.el.getElementsByClassName("remove-btn")[0];
    buttonClick.addEventListener("click", e => {
      e.preventDefault();
      e.stopPropagation();

      this.props.deleteVacation(info.event.id);
    });
    // buttonClick.onClick = (e) =>{

    //   this.props.deleteVacation(info.event.id);
    // }
  };

  handleDateClick = arg => {
    this.setState({
      // startDate: moment(arg.date).format("YYYY-MM-DD HH:mm"),
      show: true,
      startDate: arg.date,
      endDate: arg.date
    });
  };

  closeCreatePopup = () => {
    this.setState({
      show: false,
      showEdit: false,
      selectedEmployees: [],
      listArr: [],
      startDate: "",
      endDate: ""
    });
  };

  getUser = employee => {
    if (this.state.selectedEmployees.includes(employee)) return;
    this.setState(
      {
        selectedEmployees: [...this.state.selectedEmployees, employee]
      },
      () => {
        this.addListArr();
      }
    );
  };

  addListArr = () => {
    const { employees } = this.props;
    let { selectedEmployees, listArr } = this.state;
    employees &&
      employees.forEach(element => {
        selectedEmployees.forEach(item => {
          if (element.id === item.id && !listArr.includes(element)) {
            listArr.push(element);
          }
        });
      });
    this.setState({
      listArr
    });
  };

  handleFilterValue = e => {
    this.setState({
      filterValue: e.target.value
    });
  };

  removeEmployee = index => {
    const list = this.state.selectedEmployees;
    list.splice(index, 1);
    this.setState({ selectedEmployees: list, listArr: list });
  };

  handleSelect = range => {
    this.setState({
      startDate: moment(range.startDate._d).format("YYYY-MM-DD HH:mm"),
      endDate: moment(range.endDate._d).format("YYYY-MM-DD HH:mm")
    });
  };

  handleEdit = e => {
    e.preventDefault();
    this.props.editEmployeeVacation(this.state);
    this.setState({ showEdit: !this.state.showEdit, selectedEmployees: [] });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.addEmployeeVacation(this.state);
    this.setState({
      show: !this.state.show,
      selectedEmployees: [],
      listArr: [],
      startDate: "",
      endDate: ""
    });
  };
}

const mapStateToProps = state => {
  return {
    projects: state.firestore.ordered.projects,
    employees: state.firestore.ordered.employees
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addEmployeeVacation: vacation => dispatch(addEmployeeVacation(vacation)),
    dropVacation: (id, startDrop, endDrop) =>
      dispatch(dropVacation(id, startDrop, endDrop)),
    editEmployeeVacation: editVacation =>
      dispatch(editEmployeeVacation(editVacation)),
    deleteVacation: id => dispatch(deleteVacation(id))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "projects" }, { collection: "employees" }])
)(Home);
