import React from "react";
import "./App.css";
import { HashRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Login from "./components/Auth/Login";
import PrivateRoute from "./components/Auth/PrivateRoute";
import CabinetWrapper from "./components/CabinetWrapper";
import Home from "./containers/Home";
import Projects from "./containers/Projects";
import Employees from "./containers/Employees";
import Vacations from "./containers/Vacations";

export const history = createBrowserHistory();

class App extends React.Component {
  render() {
    
    return (
      <Router history={history}>
          <PrivateRoute path="/" exact component={CabinetWrapper} />
          <Route path="/login" exact component={Login} />
          <CabinetWrapper>
            <PrivateRoute path="/home" exact component={Home} />
            <PrivateRoute path="/projects" component={Projects} />
            <PrivateRoute path="/employees" component={Employees} />
            <PrivateRoute path="/vacations" component={Vacations} />
          </CabinetWrapper>
      </Router>
    );
  }
}

export default App;
