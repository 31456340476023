import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";


const PrivateRoute = ({ auth, component: RouteComponent, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routeProps =>
        auth.uid ? (
          <RouteComponent {...routeProps} />

        ) : (
          <Redirect to={{pathname: '/login', state: {from: routeProps.location}}} />
        )
      }
    />
  );
};


const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(
  mapStateToProps,
  null
)(PrivateRoute);