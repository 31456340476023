import React, { useState, useRef, useEffect } from "react";
import closePopup from "../../assets/employees/closePopup.svg";
import { DateRange } from "react-date-range";
import PreviewListEployees from "../Popup/PreviewListEmployes";
import calendar from "../../assets/icons/calendar.svg";
// import vacation from "../../assets/icons/vacations.svg";
import moment from "moment";

export const CreateProjectPopup = (
  {
    employees,
    selectedEmployees,
    removeEmployee,
    listArr,
    handleChange,
    getUser,
    closeCreatePopup,
    handleSubmit,
    filterValue,
    handleFilterValue,
    handleSelect,
    startDate,
    endDate
  } = this.props
) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  function handleStartDate(date) {
    let startDefault = moment(startDate).format("ddd MMM D YYYY 09:00");
    let endDefault = moment(endDate).format("ddd MMM D YYYY 23:29");
    date.startDate._d = new Date(startDefault);
    date.endDate._d = new Date(endDefault);
  }

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangePopup = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div className="popup">
      <div className="popup_inner project-create">
        <img
          onClick={closeCreatePopup}
          src={closePopup}
          alt="close"
          className="button-close-popup"
        />
        <div>
          <div className="project-create-form">
            <form onSubmit={handleSubmit}>
              <h1 className="popup-title">Add worker</h1>
              <div className="project-create-block" ref={node}>
                <div className="project-create-column">
                  <div className="custom-input__group">
                    <label className="project-create--label">Name</label>
                    <div className="create-search">
                      <input
                        type="text"
                        className="custom-input custom-input--employee"
                        onChange={e => handleFilterValue(e)}
                        onClick={e => setOpen(!open)}
                        value={filterValue || ""}
                        placeholder="Search..."
                      />
                      <div
                        className={
                          open ? "arrow-down arrow-rotate" : "arrow-down"
                        }
                      ></div>
                    </div>
                  </div>
                  {!open ? (
                    <div>
                      {selectedEmployees.length === 0 ? null : (
                        <div className="scroll-preview">
                          <PreviewListEployees
                            employees={employees}
                            selectedEmployees={selectedEmployees}
                            listArr={listArr}
                            handleChange={handleChange}
                            removeEmployee={removeEmployee}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="list-employees">
                      <div className="list-content">
                        <div className="list-content-employees">
                          {employees &&
                            employees
                              .filter(
                                employee =>
                                  employee.lastName
                                    .toLowerCase()
                                    .includes(filterValue.toLowerCase()) ||
                                  employee.firstName
                                    .toLowerCase()
                                    .includes(filterValue.toLowerCase())
                              )
                              .map(
                                employee => (
                                  <div
                                    key={employee.id}
                                    className="employees-name"
                                    onClick={() => {
                                      getUser(employee);
                                      handleChangePopup();
                                    }}
                                    value={employee.id}
                                  >{`${employee.firstName} ${employee.lastName}`}</div>
                                )
                              )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="project-create-second">
                  <label className="project-create--label">Date</label>
                  <div className="custom-input custom-input--employee">
                    <p
                      id="custom-label"
                      className="project-size project-size-select"
                    >
                      <img
                        src={calendar}
                        alt="calendar"
                        className="create-calendar"
                      />
                      {startDate === "" || endDate === ""
                        ? null
                        : `${moment(startDate).format("l")} - ${moment(
                            endDate
                          ).format("l")}`}
                    </p>
                  </div>
                  <DateRange
                    onChange={handleSelect}
                    calendars={1}
                    firstDayOfWeek={1}
                    date={moment(startDate)}
                    startDate={moment(startDate)}
                    endDate={moment(endDate)}
                  />
                </div>
              </div>
              <div className="create-project-button">
                <button
                  className="add-button"
                  disabled={selectedEmployees.length === 0}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectPopup;
