import React, { useState, useRef, useEffect } from "react";
import { useFirebase } from "react-redux-firebase";
import closePopup from "../../assets/employees/closePopup.svg";
import addIcon from "../../assets/projects/add_worker.svg";
import addWorker from "../../assets/employees/upload_photo.svg";
import ListEmployees from "./ListEmployees";
import PreviewListEployees from "./PreviewListEmployes";
import remove from "../../assets/remove.svg";

export const EditEmployeePopup = (
  {
    toggleEdit,
    handleChange,
    project,
    employeeInfo,
    employees,
    selectedEmployees,
    removeEmployee,
    listArr,
    addListArr,
    getUserId,
    handleUpdate,
    handleDeleteEmployeeProject,
    changeStatus
  } = this.props
) => {
  const [numb, setNumb] = useState([]);
  const [open, setOpen] = useState(false);
  const node = useRef();

  const firebase = useFirebase();


  function getNumber() {
    const Firebase = firebase.firestore();
    Firebase.collection("projects")
      .doc(project.id)
      .collection("employeesSubcollect")
      .get()
      .then(snap => {
        setNumb(snap.docs.length);
      });
  }

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangePopup = () => {
    setOpen(false);
  };

  useEffect(() => {
    getNumber();
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numb]);

  return (
    <div className="popup" id="popup-edit">
      <div className="popup_inner project-popup">
        <img
          onClick={toggleEdit}
          src={closePopup}
          alt="close"
          className="button-close-popup"
        />
        <div className="project-popup-edit">
          <h1 className="popup-title">Edit project</h1>
          <form
            onSubmit={e => {
              handleUpdate(e, project.id);
              toggleEdit();
            }}
            className="project-form-edit"
          >
            <div className="form-edit-column">
              <div className="custom-input__group">
                <input
                  className="custom-input custom-input--project"
                  type="text"
                  name="project"
                  placeholder="NewProject"
                  id="name"
                  onChange={handleChange}
                  defaultValue={project.name}
                  minLength={1}
                />
              </div>
              <div className="radio-button-status">
                <div className="radio">
                  <input
                    type="radio"
                    value="In progress"
                    defaultChecked={project.status === "In progress"}
                    id="in progress"
                    name="radio"
                    onClick={() => changeStatus("In progress")}
                  />
                  <label htmlFor="in progress" className="radio-label">
                    In progress
                  </label>
                </div>
                <div className="radio">
                  <input
                    type="radio"
                    value="Finished"
                    defaultChecked={project.status === "Finished"}
                    id="finished"
                    name="radio"
                    onClick={() => changeStatus("Finished")}
                  />
                  <label htmlFor="finished" className="radio-label">
                    Finished
                  </label>
                </div>
              </div>
            </div>
            <div className="form-edit-column border-bottom">
              <div className="label-workers">
                <p id="custom-label">Workers</p>
              </div>
              <div className="add-list" ref={node}>
                {!open ? (
                  <div>
                    <div className="add-worker" onClick={e => setOpen(!open)}>
                      <picture className="project-picture">
                        <img src={addIcon} alt="add" />
                      </picture>
                      <p>Add worker</p>
                    </div>
                    <div className="scroll-edit">
                      <div
                        className="custom-input custom-input--project"
                        multiple={true}
                        id="workers"
                        onChange={handleChange}
                        defaultValue={project.selectedEmployees}
                      >
                        {employeeInfo.map(employeeInfo => (
                          <div
                            className="preview-list-employee"
                            key={employeeInfo.id}
                          >
                            <div
                              className="employee-in-edit-project"
                              value={`${employeeInfo.firstName} ${employeeInfo.lastName}`}
                              onChange={handleChange}
                            >
                              <img
                                src={
                                  employeeInfo.picture === null
                                    ? addWorker
                                    : employeeInfo.picture
                                }
                                alt="employeeInfo-icon"
                                className="edit-project-employee-icon"
                              ></img>
                              {`${employeeInfo.firstName} ${employeeInfo.lastName}`}
                            </div>
                            <img
                              src={remove}
                              alt="remove"
                              className="remove-button"
                              onClick={() => {
                                handleDeleteEmployeeProject(
                                  project.id,
                                  employeeInfo.id
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      {selectedEmployees.length === 0 ? null : (
                        <PreviewListEployees
                          employees={employees}
                          selectedEmployees={selectedEmployees}
                          listArr={listArr}
                          handleChange={handleChange}
                          removeEmployee={removeEmployee}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <ListEmployees
                    employees={employees}
                    selectedEmployees={project.selectedEmployees}
                    toggleProject={handleChangePopup}
                    addListArr={addListArr}
                    getUserId={getUserId}
                  />
                )}
              </div>
            </div>
            <button className="add-button">Save</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditEmployeePopup;
