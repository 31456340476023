import React from "react";
import remove from "../../assets/remove.svg";


const ListPosition = ({ togglePosition, getPositionItem } = this.props) => (
  <div className="list-employees list-position">
    <img
      src={remove}
      alt="remove"
      className="popup-close popup-close-project"
      onClick={() => {
        togglePosition();
      }}
    />
    <div className="list-content">
      <p className="list-content-title">Position:</p>

      <div
        className="employees-name"
        onClick={() => {
          getPositionItem("employee");
          togglePosition();
        }}
      >
        Employee
      </div>
      <div
        className="employees-name"
        onClick={() => {
          getPositionItem("manager");
          togglePosition();
        }}
      >
        Manager
      </div>
    </div>
  </div>
);


export default ListPosition;
