import React, { useState, useEffect } from "react";
import { useFirebase } from "react-redux-firebase";
import closePopup from "../../assets/employees/closePopup.svg";
import { DateRange } from "react-date-range";
import calendar from "../../assets/icons/calendar.svg";
// import vacation from "../../assets/icons/vacations.svg";
import moment from "moment";

export const EditVacationPopup = (
  {
    selectedEmployees,
    closeCreatePopup,
    handleEdit,
    handleSelect,
    startDate,
    endDate,
    selectDate
  } = this.props
) => {
  const [employee, setEmployee] = useState([]);
  const firebase = useFirebase();

  function getEmployee() {
    const Firebase = firebase.firestore();
    Firebase.collection("employees")
      .doc(selectedEmployees)
      .get()
      .then(user => {
        setEmployee(user.data());
      });
  }

  function handleStartDate(date) {
    // let startDefault = moment(employee.start).format("ddd MMM D YYYY h:mm");
    // let endDefault = moment(employee.end).format("ddd MMM D YYYY h:mm");
    date.startDate._d = selectDate.start;
    date.endDate._d = selectDate.end;
  }

  useEffect(() => {
    getEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="popup">
      <div className="popup_inner project-create">
        <img
          onClick={closeCreatePopup}
          src={closePopup}
          alt="close"
          className="button-close-popup"
        />
        <div>
          <div className="project-create-form">
            <form onSubmit={handleEdit}>
              <h1 className="popup-title">Edit vacation</h1>
              <div className="project-create-block">
                <div className="project-create-column">
                  <div className="custom-input__group">
                    <label className="project-create--label">Name</label>
                    <div className="create-search">
                      <input
                        type="text"
                        className="custom-input custom-input--employee"
                        defaultValue={employee.firstName}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="project-create-second">
                  <label className="project-create--label">Date</label>
                  <div className="custom-input custom-input--employee">
                    <p
                      id="custom-label"
                      className="project-size project-size-select"
                    >
                      <img
                        src={calendar}
                        alt="calendar"
                        className="create-calendar"
                      />
                      {startDate === "" || endDate === ""
                        ? `${moment(employee.start).format("l")} - ${moment(
                            employee.end
                          ).format("l ")}`
                        : `${moment(startDate).format("l")} - ${moment(
                            endDate
                          ).format("l ")}`}
                    </p>
                  </div>
                  <DateRange
                    onChange={handleSelect}
                    calendars={1}
                    firstDayOfWeek={1}
                    onInit={handleStartDate}
                    // date={moment(startDate)}
                    // startDate={moment(startDate)}
                    // endDate={moment(endDate)}
                  />
                </div>
              </div>
              <div className="create-project-button">
                <button className="add-button">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVacationPopup;
