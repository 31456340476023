import React from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { signIn, signOut } from "../../store/action/authActions";

import logo from "../../assets/logo.svg";
import Loader from "../Loader/Loader";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();
    this.props.signIn(this.state);
  }

  componentDidMount() {
    let cred = sessionStorage.getItem("email");
    if (cred) {
      this.setState({ loading: true });
    }
  }

  render() {
    const { auth } = this.props;
    const { from } = this.props.location.state || {
      from: { pathname: "/home" }
    };

    if (auth.uid && from.pathname === "/") {
      this.setState({ loading: false });
      return <Redirect to="/home" />;
    } else if (auth.uid) {
      this.setState({ loading: false });
      return <Redirect to={from} />;
    }
    const loader = this.state.loading ? <Loader /> : null;
    return (
      <div className="login-page">
        {loader}
        <div className="login-page-form">
          <img src={logo} alt="logo" className="logo-img" />
          <form onSubmit={this.handleLogin} className="login-form">
            <h1 className="form-title">Log in</h1>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--auth"
                type="email"
                name="email"
                placeholder="User name"
                id="email"
                maxLength={20}
                onChange={this.handleChange}
              />
            </div>
            <div className="custom-input__group">
              <input
                className="custom-input custom-input--auth"
                type="password"
                name="password"
                placeholder="Password"
                id="password"
                maxLength={20}
                onChange={this.handleChange}
              />
            </div>
            {/* <div className="custom-input__group">
            <input
              className="custom-input custom-input--remember"
              type="email"
              name="email"
              placeholder="Don’t remember your password?"
              id="email"
              onChange={this.handleChange}
            />
          </div> */}

            <button type="submit" className="button-login">
              Log in
            </button>
          </form>
        </div>
        <div className="login-page-info">
          <h2 className="info-title">
            Lorem ipsum dolor sit amet, consectetur
          </h2>
          <p className="info-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus
            scelerisque ullamcorper pharetra sagittis amet, elementum enim.{" "}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
    signOut: () => dispatch(signOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
